import { useRef, useState } from 'react';
import { Alert, Box, Stack, TextField, Typography } from '@mui/material';
import DashboardLayout from '../../components/layouts/Dashboard/Dashboard';
import type { UploadedFileInterface } from '../../components/atoms/ListingFileUploadInput/ListingFileUploadInput';
import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';
import WaiverFileUploadInput from '../../components/atoms/WaiverFileUploadInput/WaiverFileUploadInput';
import './styling.scss';
import { useNavigate } from 'react-router-dom';
import { ROUTER_URLS } from '../../constants/router-urls';
import { useFetchWaiverEnabled } from '../../hooks/fetchSigningTemplate';
import SigningTemplateSignUp from '../../components/compounds/SigningTemplate/SigningTemplatesSignUp';

interface IProps extends WrappedProps {};

function WaiverUploads({ getConfig, postConfig, getCurrentUserId }: IProps) {
  const [title, setTitle] = useState<string>('');
  const [boldSignWaiverUrl, setBoldSignWaiverUrl] = useState<string>();
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFileInterface[]>([]);
  const imagesToUploadCount = useRef(0);

  const { data: userWaiverEnabled, isLoading } = useFetchWaiverEnabled(getConfig);

  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();
  window.addEventListener("message", function (params) {
    if (params.origin !== "https://app.boldsign.com") {
        return;
    }

    if (params.data.pageType !== 'FillPage') {
      switch (params.data.action) {
        case "TemplateCreate":
        case "DraftSave":
          navigate(ROUTER_URLS.waiverTemplates.index, { state: {
            sendStatus: 'success', toastMessage: `Your new form has been saved and is ready to be sent`
          } });
          break;
        case "onLoadComplete":
          break;  
        default:
          setErrorMessage('Failed to save the template. Please make sure you have added fields to be signed before saving.');
          break;
      } 
    }
  });
  const enabled = Boolean(userWaiverEnabled?.boldsign)
  return (
    <DashboardLayout loading={isLoading}>
      { errorMessage && errorMessage.length > 0 && <Alert severity="error">
        { errorMessage }
      </Alert>}
      { enabled ?
        <Stack sx={{ marginTop: 5, marginBottom: 10 }}>
          {!boldSignWaiverUrl && <Box sx={{ display: 'flex', justifyContent: "center" }}>
            <Typography variant='h5' component='h5' align='center' sx={{ mt: 3, mb: 3, display: 'flex', justifyContent: 'center', justifyItems: 'center', width: '75%', alignItems: 'center' }}>
              Upload your form to configure e-signature!
            </Typography>
          </Box>}
          {!boldSignWaiverUrl && <>
            <Box sx={{ marginX: 2, marginY: 2 }}>
              <TextField
                required
                id="title-outlined-required"
                label="Form Title"
                value={title}
                onChange={(e) => { setTitle(e.target.value) }}
                fullWidth
              />
            </Box>
            <Box>
              <WaiverFileUploadInput
                promptText='Drag and drop your PDF here or'
                buttonText='Upload form'
                uploadedFiles={uploadedFiles}
                setUploadedFiles={setUploadedFiles}
                postConfig={postConfig}
                filesToUploadCount={imagesToUploadCount}
                // fileAlreadyUploaded={imageAlreadyUploaded}
                setBoldSignTemplateUrl={setBoldSignWaiverUrl}
                waiverTitle={title}
              />
            </Box>
          </>}
          {boldSignWaiverUrl && boldSignWaiverUrl.length > 0 && <Box>
            <iframe title="boldSignConnection" width="100%" height="100%" id="boldsign-iframe" src={boldSignWaiverUrl}></iframe>
          </Box>}
        </Stack> :
        <SigningTemplateSignUp />
      }
    </DashboardLayout>
  );
}

export default withCommonTools(WaiverUploads);
