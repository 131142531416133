import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Orders from './Orders';

import { withCommonTools } from '../../../compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../../compounds/CommonWrapper/withCommonTools';
import { useReportRecentDeposits } from '../../../../hooks/fetchReports';
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { CircularProgress, Typography } from '@mui/material';
// import SignificantNumberCard from '../../SignificantNumberCard/SignificantNumberCard';
// import CustomLink from '../../../atoms/CustomLink/CustomLink';
// import { useAuthUser } from 'react-auth-kit';

interface IProps extends WrappedProps {};

function SalesReportLayout({ getConfig }: IProps) {
  // const auth = useAuthUser();
  // const userInfo = auth();

  const { data: recentDepositsReportData, isLoading } = useReportRecentDeposits(getConfig);

  const formatYAxis = (value: number) => {
    if (value < 100) return '';
    if (value > 999) {
      const divided = value / 1000;
      return `$${(divided.toFixed(1))}k`;
    }
    return `$${value}`;
  }

  return (
    <Grid container paddingY={2} marginTop={5} alignItems='center'>
      <Grid container direction='row' spacing={3}>
        {/* Chart */}
        <Grid item lg={12} xs={12}>
          <Paper elevation={2} sx={{ borderRadius: 3 }}>
            <Box width='100%' sx={{ display: 'flex', justifyContent: 'center', justifyItems: 'center', borderBottom: 1, borderColor: 'divider' }}>
              <Typography
                variant='body1'
                component='p'
                align='center'
                sx={{
                  mt: 2.5,
                  mb: 2.5,
                  display: 'flex',
                  justifyContent: 'center',
                  justifyItems: 'center',
                  width: '75%',
                  alignItems: 'center',
                  fontWeight: 600,
                }}
              >
                Last 6 Months Payouts
              </Typography>
            </Box>
            {isLoading ? 
              <Box sx={{
                mt: 3,
                mb: 3,
                display: 'flex',
                justifyContent: 'center',
                justifyItems: 'center',
                alignItems: 'center',
                minHeight: '400px',
              }}>
                <CircularProgress />
              </Box> :
              <Box height={250} sx={{ paddingY: 5 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart
                    width={500}
                    height={300}
                    data={recentDepositsReportData?.recentDepositChartData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <XAxis dataKey="month" />
                    <YAxis tickFormatter={formatYAxis} />
                    <Tooltip />
                    <Line type="monotone" dataKey="amount" stroke="#82ca9d" />
                  </LineChart>
                </ResponsiveContainer>
              </Box>
            }
          </Paper>
        </Grid>
      </Grid>

      <Grid container marginY={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <Orders data={recentDepositsReportData?.depositsDetail ?? []} />
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withCommonTools(SalesReportLayout);