import React, { useState } from 'react';
import { Button, Grid, Typography } from "@mui/material";
import './styling.scss';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import axios from 'axios';
import { computeChecksumMd5, uploadToS3 } from '../../../utils/fileUpload';
import { API_V3_ALLIGATOR_URLS } from '../../../constants/api-urls';

export interface UploadedFileInterface {
  id?: number;
  signedId?: string;
  file?: File;
  url?: string;
}

interface IProps {
  promptText: string;
  buttonText: string;
  uploadedFiles: UploadedFileInterface[]
  setUploadedFiles: (newFile: UploadedFileInterface[]) => void;
  postConfig: {
    headers: {
      Authorization: string;
    };
  };
  filesToUploadCount: React.MutableRefObject<number>;
  setBoldSignTemplateUrl: (newValue: string) => void;
  waiverTitle: string;
}

function WaiverFileUploadInput({
  promptText,
  buttonText,
  uploadedFiles,
  setUploadedFiles,
  postConfig,
  filesToUploadCount,
  setBoldSignTemplateUrl,
  waiverTitle,
}: IProps) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [dragActive, setDragActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [loading, setLoading] = useState(false);

  const handleDrag = (e: React.DragEvent<HTMLFormElement | HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const getS3Url = async (newFile: File) => {
    const checkSum = await computeChecksumMd5(newFile);
    const data = await axios.post(
      API_V3_ALLIGATOR_URLS.privateDirectUpload.create,
      {
        blob: {
          filename: newFile.name,
          content_type: newFile.type,
          byte_size: newFile.size,
          checksum: checkSum, /* base 64 of the MD5 hash of the file */
        }
      },
      postConfig
    );

    return data;
  };

  const handleUpload = async function (files: FileList | null) {
    try {
      setLoading(true);
      if (files?.[0]) {
        filesToUploadCount.current = files.length;
  
        const tempFiles = [...uploadedFiles]
        const file = files?.[0]
  
        const s3UrlRes = await getS3Url(file);
  
        if (s3UrlRes.data.direct_upload) {
          const directUpload = s3UrlRes.data.direct_upload;
  
          const uploadToS3Res = await uploadToS3(directUpload.url, directUpload.headers, file)
          if (uploadToS3Res.status === 200) {
  
            const newUploadedFile = {
              // id: res.data.id,
              signedId: s3UrlRes.data.signed_id,
              file,
            }
  
            const params = {
              signing_template: {
                title: waiverTitle,
                file: newUploadedFile.signedId
              }
            }
            const buildTemplateRes = await axios.post(
              API_V3_ALLIGATOR_URLS.signingTemplates.create,
              params,
              postConfig
            )
  
            if (buildTemplateRes.status === 200) {
              setBoldSignTemplateUrl(buildTemplateRes.data.templateUrl);
            }
  
            tempFiles.push(newUploadedFile);
          }
        }
  
        const tempCount = filesToUploadCount.current;
        filesToUploadCount.current = (tempCount - 1);
        // }
        setUploadedFiles(tempFiles);
      }  
    } finally {
      setLoading(false);
    }

  }

  const handleDrop = async function (e: React.DragEvent<HTMLDivElement>) {
    if (waiverTitle.length === 0) return;

    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    await handleUpload(e.dataTransfer.files);
  };

  // triggers when file is selected with click
  const handleChange = async function (e: React.ChangeEvent<HTMLInputElement>) {
    if (waiverTitle.length === 0) return;

    setErrorMessage(undefined);
    e.preventDefault();
    await handleUpload(e.target.files);
  };

  const onButtonClick = () => {
    if (inputRef?.current) {
      inputRef.current.click();
    }
  };

  if (filesToUploadCount.current > 0 || loading) {
    return (
      <Grid container direction='row' spacing={2} padding={2}>
        <Grid item xs={12} lg={12}>
          <LoadingSpinner />
        </Grid>
      </Grid>
    )
  }

  if (waiverTitle.length === 0) {
    return (
      <Grid container direction='row' spacing={2} padding={2}>
        <Grid item xs={12} lg={12}>
          <form id="file-upload-form">
            <label id="file-upload-label-disabled" htmlFor="file-upload-input" className="">
              <div>
                <Typography
                  variant='body1'
                  component='p'
                  sx={{
                    mt: 0,
                    mb: 0,
                    padding: 4,
                    display: 'flex',
                    alignItems: 'center',
                    alignContent: 'center',
                    height: '100%',
                  }}
                >
                  Add a title to upload your form
                </Typography>
              </div>
            </label>
          </form>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container direction='row' spacing={2} padding={2}>
      <Grid item xs={12} lg={12}>
        <form id="file-upload-form" onDragEnter={handleDrag} onSubmit={(e) => { handleSubmit(e) }} >
          {
            // this is a fire and forget function for now
            // https://stackoverflow.com/questions/63488141/promise-returned-in-function-argument-where-a-void-return-was-expected
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            <input ref={inputRef} type="file" accept="application/pdf" id="file-upload-input" multiple={true} onChange={async (e) => { await handleChange(e) }} />
          }
          <label id="file-upload-label" htmlFor="file-upload-input" className={dragActive ? "drag-active" : ""}>
            <div>
              {
                errorMessage ?
                  <Typography
                    variant='body1'
                    component='p'
                    color='error'
                    sx={{
                      mt: 0,
                      mb: 0,
                      padding: 4,
                      display: 'flex',
                      alignItems: 'center',
                      alignContent: 'center',
                      height: '100%',
                      fontWeight: 600,
                    }}
                  >
                    {errorMessage}
                  </Typography> :
                  <Typography
                    variant='body1'
                    component='p'
                    sx={{
                      mt: 0,
                      mb: 0,
                      padding: 4,
                      display: 'flex',
                      alignItems: 'center',
                      alignContent: 'center',
                      height: '100%',
                    }}
                  >
                    {promptText}
                  </Typography>
              }
              <Button
                onClick={onButtonClick}
                className="upload-button"
                variant="text"
              >
                {buttonText}
              </Button>
            </div>
          </label>
          {
            // this is a fire and forget function for now
            // https://stackoverflow.com/questions/63488141/promise-returned-in-function-argument-where-a-void-return-was-expected
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={async (e) => { await handleDrop(e) }}></div>
          }
        </form>
      </Grid>
    </Grid>
  );
}

export default WaiverFileUploadInput;
