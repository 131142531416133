import React from 'react';

import { Grid } from '@mui/material';
import DashboardLayout from '../../components/layouts/Dashboard/Dashboard';
import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';
import { useNavigate, useParams } from 'react-router-dom';
import { useFetchEditSigningTemplate } from '../../hooks/fetchSigningTemplate';
import { ROUTER_URLS } from '../../constants/router-urls';


interface IProps extends WrappedProps {};

function EditTemplate({ postConfig }: IProps) {

  const { id: templateId } = useParams();
  const { data: signingTemplateRecord, isLoading } = useFetchEditSigningTemplate(postConfig, templateId)
  
  

  const navigate = useNavigate();
  window.addEventListener("message", function (params) {
    if (params.origin !== "https://app.boldsign.com") {
        return;
    }

    switch (params.data.status) {
      case "onTemplateEditingCompleted":
        navigate(ROUTER_URLS.waiverTemplates.index);
        break;
      default:
        break;
    }
  });

  return (
    <DashboardLayout loading={isLoading}>
      <Grid container direction='column'>
        <Grid item marginTop={5} marginBottom={10}>
          {signingTemplateRecord?.templateUrl && signingTemplateRecord.templateUrl.length > 0 && <Grid container direction='row'>
            <iframe title="boldSignConnection" width="100%" height="100%" id="boldsign-iframe" src={signingTemplateRecord.templateUrl}></iframe>
          </Grid>}
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default withCommonTools(EditTemplate);
