import React from 'react';

import { Grid } from '@mui/material';
import DashboardLayout from '../../components/layouts/Dashboard/Dashboard';
import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';
import { useParams } from 'react-router-dom';
import { useFetchSigningTemplate } from '../../hooks/fetchSigningTemplate';

interface IProps extends WrappedProps {};

function ShowTemplate({ postConfig }: IProps) {

  const { id: templateId } = useParams();
  const { data: signingTemplateRecord, isLoading } = useFetchSigningTemplate(postConfig, templateId)

  return (
    <DashboardLayout loading={isLoading}>
      <Grid container direction='column'>
        <Grid item marginTop={5} marginBottom={10}>
          {signingTemplateRecord?.previewUrl && signingTemplateRecord.previewUrl.length > 0 && <Grid container direction='row'>
            <iframe title="boldSignConnection" width="100%" height="100%" id="boldsign-iframe" src={signingTemplateRecord.previewUrl}></iframe>
          </Grid>}
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default withCommonTools(ShowTemplate);