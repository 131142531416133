import { useState } from "react";
import { Box, Button, CircularProgress, FormControl, IconButton, MenuItem, Paper, Select, Stack, Typography } from "@mui/material";
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import type { SelectChangeEvent } from '@mui/material';
import { useFetchListings } from "../../../hooks/fetchListing";
import { withCommonTools } from '../CommonWrapper/withCommonTools';
import type { WrappedProps } from '../CommonWrapper/withCommonTools';
import { useUserCanAccessBookNow } from '../../../hooks/userCanAccess';
import SendIcon from '@mui/icons-material/Send';
import { listingDisplayName } from "../../../utils/listing";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { updateTimeToCurrent } from "../../../utils/dates";
import { format, isAfter, isBefore } from "date-fns";
import ClearIcon from '@mui/icons-material/Clear';

interface IProps extends WrappedProps {};

function QuickShareCard({ postConfig }: IProps) {
  const canAccessBookNow = useUserCanAccessBookNow();
  const { data: listingRecords, isLoading } = useFetchListings(postConfig);

  const [selectedListingId, setSelectedListingId] = useState<string>('');
  const [loadingShare, setLoadingShare] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const shareUrl = () => {
    setLoadingShare(true);
    const listing = listingRecords?.find((listing) => { return listing.id.toString() === selectedListingId });
    if (listing) {
      let params = ''
      if (startDate && endDate) {
        params = `?start=${format(startDate, 'M/d/yy')}&end=${format(endDate, 'M/d/yy')}`;
      }
      const previewLink = canAccessBookNow ? `https://www.huntpay.com/listing/s/${listing.bookNowSlug}/checkout${params}` : `https://www.venku.com/listing/s/${listing.slug}/checkout${params}`
      const title = canAccessBookNow ? 'HuntPay' : 'Venku';
      const text = canAccessBookNow ? 'Reserve your hunt with HuntPay' : 'Book your hunt with Venku';
      const shareData = {
        title,
        text,
        url: previewLink,
      };
      if (navigator.canShare(shareData)) {
        navigator.share(shareData).then(() => {
          setSelectedListingId('')
          setLoadingShare(false);
        }).catch((e) => {
          console.log(e)
          setLoadingShare(false);
        });
      }
    } else {
      setLoadingShare(false);
    }
  }

  const handleListingSelectChange = (event: SelectChangeEvent) => {
    setSelectedListingId(event.target.value);
  };

  const validDates = (startDate === null && endDate === null) || (startDate !== null && endDate !== null && isBefore(startDate, endDate) && isAfter(endDate, startDate)); 

  const shareDisabled = (): boolean => {
    return selectedListingId === '' || loadingShare || !validDates;
  }

  return (
    <Paper elevation={2} sx={{ borderRadius: 3, height: 1, display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', justifyItems: 'center', borderBottom: 1, borderColor: 'divider', width: '100%',  }}>
        <Typography
          variant='h6'
          component='h6'
          align='center'
          sx={{
            mt: 2,
            mb: 2,
            display: 'flex',
            justifyContent: 'center',
            justifyItems: 'center',
            width: '75%',
            alignItems: 'center',
            fontWeight: 600,
          }}
        >
          Quick Share
        </Typography>
      </Box>        
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        justifyItems: 'center',
        borderColor: 'divider',
        flexGrow: 1,
        width: '100%',
      }}>
        <Stack spacing={2} sx={{ display: 'flex', flexGrow: 1, margin: 3, justifyContent: 'center', justifyItems: 'center', alignItems: 'center', }}>
        { isLoading ?
          <Box sx={{
            mt: 3,
            mb: 3,
            display: 'flex',
            justifyContent: 'center',
            justifyItems: 'center',
            alignItems: 'center',
            minHeight: '72px',
          }}>
            <CircularProgress />
          </Box> :
          <>
            <FormControl fullWidth>
              <Select
                labelId="listing-label"
                id="listing-select"
                value={selectedListingId}
                onChange={handleListingSelectChange}
                fullWidth
                placeholder='Select Package'
                displayEmpty
              >
                <MenuItem value="">
                  Select Package
                </MenuItem>
                {listingRecords && listingRecords.length > 0 && listingRecords.map((listing) => {
                    return (
                      <MenuItem key={listing.id} value={listing.id.toString()}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                          <Box
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            sx={{ backgroundColor: listing.hexColor, height: '20px', width: '20px', borderRadius: 1 }}
                          /><Typography>{listingDisplayName(listing)}</Typography>
                        </Box>
                      </MenuItem>
                    )
                  })}
              </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid2 container spacing={2}>
                <Grid2 xs={12} sm={6}>
                  <DatePicker
                    label="Start Date"
                    value={startDate}
                    onChange={(newValue) => {
                      updateTimeToCurrent(newValue)
                      setStartDate(newValue)
                    }}
                    disablePast
                    slotProps={{
                      inputAdornment: {
                        position: 'start',
                      },
                      textField: {
                        InputProps: {
                          endAdornment: (
                            startDate && <IconButton onClick={(e) => {
                              e.stopPropagation();
                              setStartDate(null)
                            }}>
                              <ClearIcon />
                            </IconButton>
                          )
                        }
                      },
                    }}
                    sx={{ width: '100%' }}
                  />
                </Grid2>
                <Grid2 xs={12} sm={6}>
                  <DatePicker
                    label="End Date"
                    value={endDate}
                    onChange={(newValue) => {
                      updateTimeToCurrent(newValue)
                      setEndDate(newValue)
                    }}
                    disablePast
                    slotProps={{
                      inputAdornment: {
                        position: 'start',
                      },
                      textField: {
                        InputProps: {
                          endAdornment: (
                            endDate && <IconButton onClick={(e) => {
                              e.stopPropagation();
                              setEndDate(null)
                            }}>
                              <ClearIcon />
                            </IconButton>
                          )
                        }
                      },
                    }}
                    sx={{ width: '100%' }}
                  />
                </Grid2>
              </Grid2>
            </LocalizationProvider>
            <Button disabled={shareDisabled()} variant='contained' onClick={shareUrl} sx={{ minWidth: { xs: '100px', md: '200px' } }} endIcon={<SendIcon />}>
              Share Link
            </Button>
          </>
        }
        </Stack>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          justifyItems: 'center',
          width: '100%',
        }}>
          <Typography variant="body2" sx={{ paddingTop: 1, textAlign: 'center', fontStyle: 'italic', paddingX: 3, marginBottom: 1, }}>
            *Quickly share a package with customers from your contacts via text or email 
          </Typography>
        </Box>
      </Box>
    </Paper>
  )
}

export default withCommonTools(QuickShareCard);