import React from 'react';
import { useAuthHeader, useAuthUser } from 'react-auth-kit';
import { ADMIN_USER } from '../../../constants/settings';
import { getSessionStorageOrDefault } from '../../../utils/storage';
// import axios from 'axios';
// import { generatePath, useNavigate } from "react-router-dom"

export interface WrappedProps {
  // saveSearch: (filterContext: any, queryString: string) => void;
  postConfig: {
    headers: {
      Authorization: string;
    };
  };
  getConfig: {
    headers: {
      Authorization: string;
    };
  };
  getCurrentUserId: () => string | undefined;
  getCurrentUserEmail: () => string | undefined;
};

interface VenkuHeadersType {
  Authorization: string;
  'X-Venku-Impersonate'?: string;
}

export function withCommonTools<T>(
  WrappedComponent: React.ComponentType<T & WrappedProps>,
  // navigateTo: string,
) {

  function ComponentWithProps(props: Omit<T, "postConfig" | "getConfig" | "getCurrentUserId" | "getCurrentUserEmail">) {
    const authHeader = useAuthHeader();
    const auth = useAuthUser();

    const token = authHeader();
    const userInfo = auth();

    const config: { headers: VenkuHeadersType } = {
      headers: {
        Authorization: token,
      }
    };

    if (userInfo?.role === ADMIN_USER) {
      const impersonateUserToken = getSessionStorageOrDefault('impersonateUserToken') as string | undefined;
      if (impersonateUserToken && impersonateUserToken.length > 0) {
        // config.headers.Authorization = `${impersonateUserToken}`;
        config.headers['X-Venku-Impersonate'] = impersonateUserToken;
      }
    }

    const getCurrentUserId = () => {
      if (userInfo?.role === ADMIN_USER) {
        const impersonateUserId = getSessionStorageOrDefault('impersonateUserId') as number | undefined;
        if (impersonateUserId && impersonateUserId > 0) {
          return impersonateUserId;
        }
      }
      return userInfo?.id;
    };

    const getCurrentUserEmail = () => {
      return userInfo?.email;
    }
   
    // const navigate = useNavigate();

    // const searchSuccessNavigate = (id: string, path: string) => {    
    //   const route = generatePath(path, { searchId: id });
    //   navigate(route, {
    //     state: { loadFiltersFromState: true },
    //     replace: true,
    //   });
    // };
  
    // const saveSearchAndNavigate = (
    //   filterContext: any,
    //   queryString: string,
    // ) => {
    //   axios.post(API_URLS.search, {
    //     context: {
    //       filterContext,
    //     },
    //     query_string: queryString,
    //   }).then((response) => {
    //     const newId = response.data.id;
    //     searchSuccessNavigate(newId, navigateTo);
    //   }).catch((error) => {
    //     // eslint-disable-next-line no-console
    //     console.log(error);
    //   });
    // };
    
    // const config = {
    //   headers: {
    //     Authorization: {
    //       token: '',
    //     },
    //   },
    // };
    
    return (
      <WrappedComponent { ...props as T }
        postConfig={config}
        getConfig={config}
        getCurrentUserId={getCurrentUserId}
        getCurrentUserEmail={getCurrentUserEmail}
      />
    )
  };
  return ComponentWithProps;
};
