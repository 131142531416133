import React, { useEffect, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Box, CircularProgress, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField,
} from '@mui/material';
import HorizontalStepper from '../../components/atoms/HorizontalStepper/HorizontalStepper';
import DashboardLayout from '../../components/layouts/Dashboard/Dashboard';
import { newListingSteps } from '../../constants/listings';
import { useFetchListing } from '../../hooks/fetchListing';
import type { ListingDetailType } from '../../types/listing';
import HorizontalStepperActions from '../../components/atoms/HorizontalStepperActions/HorizontalStepperActions';
import { TIME_ZONES_DROPDOWN_OPTIONS } from '../../constants/settings';
import { API_V3_ALLIGATOR_URLS } from '../../constants/api-urls';
import axios from 'axios';
import { ROUTER_URLS } from '../../constants/router-urls';
import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { SelectChangeEvent } from '@mui/material';
import type { SettingTimeZoneType } from '../../types/setting';
import { listingStepTwoValid } from '../../utils/listing';
import { GooglePlacesAutocompleteV2 } from '../../components/compounds/GooglePlacesAutocompleteV2/GooglePlacesAutocompleteV2';
import { APIProvider, Map } from '@vis.gl/react-google-maps';

interface IProps extends WrappedProps {};

const InlineLoading = (
  <Grid item lg={10} xs={12}> 
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
      <CircularProgress />
    </Box>
  </Grid>
);

const findAddressElementFromGooglePlace = (element: string, placeDetail: google.maps.GeocoderAddressComponent[]): string | undefined => {
  const placeAttribute = placeDetail.find((place) => place.types.includes(element));
  if (placeAttribute) return placeAttribute.long_name;
  return undefined; 
}

const findShortAddressElementFromGooglePlace = (element: string, placeDetail: google.maps.GeocoderAddressComponent[]): string | undefined => {
  const placeAttribute = placeDetail.find((place) => place.types.includes(element));
  if (placeAttribute) return placeAttribute.short_name;
  return undefined; 
}

function EditListingAddress({ getConfig, postConfig }: IProps) {
  const { id: listingId } = useParams();
  const navigate = useNavigate();

  const activeStep = 1;
  const [addressOneValue, setAddressOneValue] = useState('');
  const [addressTwoValue, setAddressTwoValue] = useState('');
  const [addressCity, setAddressCity] = useState('');
  const [addressZip, setAddressZip] = useState('');
  const [addressLatitude, setAddressLatitude] = useState('');
  const [addressLongitude, setAddressLongitdue] = useState('');
  const [addressState, setAddressState] = React.useState('');
  const [addressRegion, setAddressRegion] = useState('');
  const [addressCountry, setAddressCountry] = useState('');

  const [timeZone, setTimeZone] = useState(TIME_ZONES_DROPDOWN_OPTIONS[0].id);

  const [isSaving, setIsSaving] = useState(false);

  const [loadingAddressFromApi, setLoadingAddressFromApi] = useState(false);

  const { data: listingRecord, isLoading } = useFetchListing(getConfig, listingId ?? '');

  useEffect(() => {
    if (listingRecord?.address) {
      setAddressOneValue(listingRecord.address.line_one);
      setAddressTwoValue(listingRecord.address.line_two);
      setAddressCity(listingRecord.address.city);
      setAddressZip(listingRecord.address.zip_code);
      setAddressLatitude(listingRecord.address.latitude?.toString() ?? '');
      setAddressLongitdue(listingRecord.address.longitude?.toString() ?? '');
      setAddressState(listingRecord.address.state);
      setAddressRegion(listingRecord.address.region);
      setAddressCountry(listingRecord.address.country);
      setTimeZone(listingRecord.timeZone);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [listingRecord])

  const resetFields = () => {
    setAddressOneValue('');
    setAddressTwoValue('');
    setAddressCity('');
    setAddressZip('');
    setAddressLatitude('');
    setAddressLongitdue('');
    setAddressState('');
    setAddressRegion('');
    setAddressCountry('');
  }

  const handleBack = () => {
    const backUrl = generatePath(ROUTER_URLS.listings.edit, { id: listingId });
    navigate({
      pathname: backUrl,
    });
  };

  const handleNext = () => {
    const nextUrl = generatePath(ROUTER_URLS.listings.editAdditionalDetails, { id: listingId });
    navigate({
      pathname: nextUrl,
    });
  };

  const handleTimeZoneChange = (event: SelectChangeEvent) => {
    setTimeZone(event.target.value);
  };

  const handleSave = (moveToNextStep: boolean) => {
    setIsSaving(true);
    const params = {
      listing: {
        address_attributes: {
          id: listingRecord?.address?.id,
          line_one: addressOneValue,
          line_two: addressTwoValue,
          city: addressCity,
          state: addressState,
          zip_code: addressZip,
          longitude: addressLongitude,
          latitude: addressLatitude,
          country: addressCountry,
          region: addressRegion,
        },
        time_zone: timeZone,
        published: true,
      }
    };

    axios.patch(`${API_V3_ALLIGATOR_URLS.listing.update}${listingId ?? ''}`, params, postConfig).then((response) => {
      const newListingData: ListingDetailType = response.data;
      if (newListingData.id) {
        if (moveToNextStep) handleNext();
        setIsSaving(false);
      } else {
        console.log('error failed to save');
      }
    }).catch((error) => {
      console.log(error);
    });
  };

  const parseAddressFromGooglePlace = (newValue: google.maps.GeocoderAddressComponent[]) => {
    const streetNumber = findAddressElementFromGooglePlace('street_number', newValue);
    const streetAddressOne = findAddressElementFromGooglePlace('route', newValue);
    const addressTwo = findAddressElementFromGooglePlace('subpremise', newValue);
    const city = findAddressElementFromGooglePlace('locality', newValue);
    const state = findShortAddressElementFromGooglePlace('administrative_area_level_1', newValue);
    const region = findAddressElementFromGooglePlace('administrative_area_level_2', newValue);
    const country = findShortAddressElementFromGooglePlace('country', newValue);
    const zip = findAddressElementFromGooglePlace('postal_code', newValue);
    
    if (streetNumber && streetAddressOne) {
      setAddressOneValue(`${streetNumber} ${streetAddressOne}`);
    }
    
    setAddressTwoValue(addressTwo ?? '');
    setAddressCity(city ?? '');
    setAddressState(state ?? '');
    setAddressZip(zip ?? '');
    setAddressRegion(region ?? '');
    setAddressCountry(country ?? '');
  };

  const onPlaceSelect = (place: google.maps.places.PlaceResult | null) => {
    setLoadingAddressFromApi(true);
    if (place) {
      const lat = place.geometry?.location?.lat();
      const long = place.geometry?.location?.lng()
      if (lat) setAddressLatitude(lat.toString());
      if (long) setAddressLongitdue(long.toString());
      if (place.address_components) {
        parseAddressFromGooglePlace(place.address_components);
        setLoadingAddressFromApi(false);  
      } else {
        setLoadingAddressFromApi(false);
      }
    } else{
      setLoadingAddressFromApi(false);
    }
  };

  // @todo: make this look nice with a link back to the listings page.
  // this should be returned if the listing id is null empty or undefined as well
  if (!listingRecord) {
    return <DashboardLayout loading />;
  }

  const AddressPanel = (
    <>
      <Grid item>
        <Grid container direction='row'>
          <Grid item lg={10} xs={12}>
            <TextField
              id="outlined-required"
              label="Address 1"
              placeholder="Street Address"
              fullWidth
              value={addressOneValue}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setAddressOneValue(event.target.value);
              }}
            />
          </Grid>
        </Grid>  
      </Grid>
      <Grid item>
        <Grid container direction='row'>
          <Grid item lg={10} xs={12}>
            <TextField
              id="outlined-required"
              label="Address 2"
              value={addressTwoValue}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setAddressTwoValue(event.target.value);
              }}
              placeholder="Apt, Suite..."
              helperText="Optional Apartment or Suite number etc."
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction='row' spacing={2}>
          <Grid item lg={4} xs={12}>
            <TextField
              id="outlined-required"
              label="City"
              value={addressCity}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setAddressCity(event.target.value);
              }}
              placeholder="City"
              fullWidth
            />
          </Grid>
          <Grid item lg={3} xs={12}>
            <TextField
              id="outlined-required"
              label="State"
              value={addressState}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setAddressState(event.target.value);
              }}
              placeholder="State"
              fullWidth
            />
          </Grid>
          <Grid item lg={3} xs={12}>
            <TextField
              id="outlined-required"
              label="Region"
              value={addressRegion}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setAddressRegion(event.target.value);
              }}
              placeholder="Region"
              fullWidth
            />
          </Grid>

          <Grid item lg={3} xs={12}>
            <TextField
              id="outlined-required"
              label="Zip"
              value={addressZip}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setAddressZip(event.target.value);
              }}
              placeholder="Zip"
              fullWidth
            />
          </Grid>
          <Grid item lg={3} xs={12}>
            <TextField
              id="outlined-required"
              label="Country"
              value={addressCountry}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setAddressCountry(event.target.value);
              }}
              placeholder="Country"
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction='row' spacing={2}>
          <Grid item lg={5} xs={12}>
            <TextField
              required
              id="outlined-required"
              label="Latitude"
              value={addressLatitude}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setAddressLatitude(event.target.value);
              }}
              fullWidth
            />
          </Grid>
          <Grid item lg={5} xs={12}>
            <TextField
              required
              id="outlined-required"
              label="Longitude"
              value={addressLongitude}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setAddressLongitdue(event.target.value);
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? '';
  return (
    <DashboardLayout loading={isLoading || isSaving}>
      <Grid container direction='column'>
        <Grid item marginTop={5} marginBottom={10}>
          <HorizontalStepper
            activeStep={activeStep}
            steps={newListingSteps}
            listingId={listingId}
          />
          <Grid container direction='column' spacing={2} padding={2}>
            <Grid item>
              <Grid container direction='row'>
                <Grid item lg={10} xs={12}>
                  <APIProvider apiKey={apiKey}>
                    <Box sx={{ display: 'none' }}>
                      <Map
                        defaultZoom={3}
                        defaultCenter={{lat: 22.54992, lng: 0}}
                        gestureHandling={'greedy'}
                        disableDefaultUI={true}
                      />
                    </Box>
                    <GooglePlacesAutocompleteV2
                      onPlaceSelect={onPlaceSelect}
                      resetFields={resetFields}
                      placeholder="Search for package address"
                      helperText="Please select the nearest point of interest. The general location of the package will work. The package's exact address will only be shared with confirmed hunters."
                    />
                  </APIProvider>
                </Grid>
              </Grid>
            </Grid>
            { 
              loadingAddressFromApi ? InlineLoading : AddressPanel  
            }
            <Grid item>
              <Grid container direction='row'>
                <Grid item lg={10} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-helper-label">Time Zone</InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={timeZone}
                      label="Time Zone"
                      onChange={handleTimeZoneChange}
                    >
                      { TIME_ZONES_DROPDOWN_OPTIONS.map((option: SettingTimeZoneType) => (
                        <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>Please select a your time zone for listing related notifications</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction='row' spacing={5} justifyContent='center'>
            <Grid item lg={10} xs={12} marginTop={5}>
              <HorizontalStepperActions
                firstStep={false}
                finalStep={false}
                handleNext={handleSave}
                handleBack={handleBack}
                handleSave={handleSave}
                isValid={listingStepTwoValid(addressLatitude, addressLongitude)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default withCommonTools(EditListingAddress);
