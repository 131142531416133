import React from 'react';
import { Grid, Stack } from '@mui/material';
import Dashboard from '../../components/layouts/Dashboard/Dashboard';
import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';
import StaffUpcomingBookingsCard from '../../components/compounds/StaffUpcomingBookingsCard/StaffUpcomingBookingsCard';
import QuickShareCard from '../../components/compounds/QuickShareCard/QuickShareCard';
import RecentDepositsCard from '../../components/compounds/RecentDepositsCard/RecentDepositsCard';
import TipQrCodeCard from '../../components/compounds/TipQrCodeCard/TipQrCodeCard';


interface IProps extends WrappedProps {};

function GuidesDashboard({ getConfig }: IProps) {

  return (
    <Dashboard>
      <Stack spacing={3} marginTop={2}>
        <div>
          <Grid container direction='row' spacing={2}>
            <Grid item lg={6} xs={12}>
              <StaffUpcomingBookingsCard getConfig={getConfig} />
            </Grid>
            <Grid item lg={6} xs={12}>
              <QuickShareCard />
            </Grid>
          </Grid>
        </div>
        
        <div>
          <Grid container direction='row' spacing={2}>
            <Grid item lg={6} xs={12}>
              <TipQrCodeCard />
            </Grid>
            <Grid item lg={6} xs={12}>
              <RecentDepositsCard getConfig={getConfig} hideLink={true} />
            </Grid>
          </Grid>
        </div>
      </Stack>
    </Dashboard>
  );
}

export default withCommonTools(GuidesDashboard);
