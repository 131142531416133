import React, { useEffect } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { generatePath, useNavigate } from 'react-router-dom';
import Dashboard from '../../components/layouts/Dashboard/Dashboard';
import { ROUTER_URLS } from '../../constants/router-urls';

import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../constants/api-urls';
import toProperCase from '../../utils/strings';
import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';
import InfoIcon from '@mui/icons-material/Info';
import type { PagedResultsType } from '../../types/pagedResults';
import EditIcon from '@mui/icons-material/Edit';

import FormattedDataGrid from '../../components/compounds/FormattedDataGrid/FormattedDataGrid';
import type { GridAlignment, GridSortModel, GridValueGetterParams } from '@mui/x-data-grid';
import { GridActionsCellItem } from '@mui/x-data-grid';
import type { StaffMemberResultType } from '../../types/staffMember';


interface IProps extends WrappedProps {};

function StaffList({ postConfig }: IProps) {
  const navigate = useNavigate();
  
  const [users, setUsers] = React.useState<StaffMemberResultType[]>([]);
  
  // const [email, setEmail] = React.useState<string>('');
  // const [firstName, setFirstName] = React.useState<string>('');
  // const [lastName, setLastName] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);

  const [rowCount, setRowCount] = React.useState(0);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 20,
  });

  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'memberSince',
      sort: 'desc',
    },
  ]);

  useEffect(() => {
    if (!loading) {
      setLoading(true);

      const params = {
        page: paginationModel.page + 1,
        sort: sortModel[0],
        // user: {
        //   first_name: firstName,
        //   last_name: lastName,
        //   email: email,
        // }
      }

      axios.post(API_V3_ALLIGATOR_URLS.staff_members.search, params, postConfig).then((response) => {
        const usersData: { users: StaffMemberResultType[], page: PagedResultsType } = response.data;
        setUsers(usersData.users);
        setRowCount(usersData.page.count);
        setLoading(false);
      }).catch((error) => {
        console.log(error);
        setLoading(false);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [paginationModel.page, sortModel[0]?.field, sortModel[0]?.sort]);

  const navigateToUser = (e: any, id: string | number) => {
    e.preventDefault();
    const path = generatePath(ROUTER_URLS.staff.show, { id });
    navigate(path);
  };

  const navigateToEditUser = (e: any, id: string | number) => {
    e.preventDefault();
    const path = generatePath(ROUTER_URLS.staff.edit, { id });
    navigate(path);
  };

  const navigateToNewHost = () => {
    const path = generatePath(ROUTER_URLS.staff.new);
    navigate(path);
  };

  // const navigateToUserBookings = (e: any, id: string | number, userType: string) => {
  //   e.preventDefault();
  //   const path = generatePath(ROUTER_URLS.admin.bookings.index);
  //   const searchParams = userType === 'user' ? `?userId=${id}` : `?hostId=${id}`
  //   navigate({ pathname: path, search: searchParams });
  // };


  const handleSortModelChange = React.useCallback((newSortModel: GridSortModel) => {
    const copiedSortModel = [...newSortModel];
    setSortModel(copiedSortModel);
  }, []);

  const gridHeaders = [
    {
      field: 'firstName',
      headerName: 'Name',
      width: 150,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => params.row.firstName ? toProperCase(params.row.firstName) : '',
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      width: 150,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => params.row.lastName ? toProperCase(params.row.lastName) : '',
    },
    {
      field: 'userType',
      headerName: 'Staff Type',
      width: 150,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => params.row.userType ? toProperCase(params.row.userType) : '',
      sortable: false,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      flex: 1,
      minWidth: 150,
      cellClassName: 'actions',
      align: 'right' as GridAlignment,
      getActions: ({ id, row }: { id: string | number, row: StaffMemberResultType }) => {
        const actionBlocks: JSX.Element[] = []

        actionBlocks.push(
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={(e: any) => { navigateToEditUser(e, row.id) }}
            color="primary"
          />,
        )
        
        // if (row.userType !== 'user') {
        //   actionBlocks.push(
        //     <GridActionsCellItem
        //       icon={<ForestIcon />}
        //       label="New Package"
        //       className="textPrimary"
        //       onClick={(e: any) => { navigateToNewListing(e, row.id) }}
        //       color="primary"
        //     />
        //   )
        // }
        return actionBlocks.concat(
          [
            <GridActionsCellItem
              icon={<InfoIcon />}
              label="View"
              className="textPrimary"
              onClick={(e: any) => { navigateToUser(e, row.id) }}
              color="warning"
            />,
            // <GridActionsCellItem
            //   icon={<MenuBookIcon />}
            //   label="Hunts"
            //   className="textPrimary"
            //   onClick={(e: any) => { navigateToUserBookings(e, row.id, row.userType) }}
            //   color="default"
            // />,
          ]
        );
      },
    },
  ]

  return (
    <Dashboard>
      <Grid container paddingY={2} marginTop={5} alignItems='center'>
        <Grid item xs={6}>
          <Typography variant="h5">Staff</Typography>
        </Grid>
        <Grid item xs={6} display='flex' justifyContent='end'>
          <Button
            type="submit"
            variant="contained"
            onClick={navigateToNewHost}
            color='secondary'
            sx={{ marginRight: 2 }}
          >
            +New Staff
          </Button>
        </Grid>
      </Grid>

      <Box sx={{ marginY: 3 }}>
        <FormattedDataGrid
          headers={gridHeaders}
          rows={users}
          totalRows={rowCount}
          pagingModel={paginationModel}
          setPagingModel={setPaginationModel}
          isLoading={loading}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
            sorting: {
              sortModel: [
                { field: 'memberSince', sort: 'desc' }
              ]
            }
          }}
          sortModel={sortModel}
          handleSortModelChange={handleSortModelChange}
        />
      </Box>
    </Dashboard>
  );
}

export default withCommonTools(StaffList);
