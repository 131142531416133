import React, { useState } from 'react';
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTER_URLS } from '../../constants/router-urls';
import toProperCase from '../../utils/strings';
import { formatDateString } from '../../utils/dates';
import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';
import { useFetchMessages } from '../../hooks/fetchMessage';
import Dashboard from '../../components/layouts/Dashboard/Dashboard';
import LoadingSpinner from '../../components/atoms/LoadingSpinner/LoadingSpinner';
// import { API_V3_ALLIGATOR_URLS } from '../../constants/api-urls';

interface IProps extends WrappedProps {};

function Messages({ getConfig, getCurrentUserId }: IProps) {
  const navigate = useNavigate();
  const toggleButtonStatuses = ['inbox', 'sent'];
  const [filterStatus, setFilterStatus] = useState('inbox');

  const { data: chats, isLoading: loading } = useFetchMessages(getConfig, filterStatus);

  const handleFilterStatusChange = (status: string) => {
    setFilterStatus(status);
  };

  const navigateToMessage = (id: number) => {
    // flagread or FlagMessagesRead

    // POST send an array of ids in the body


    const path = generatePath(ROUTER_URLS.messages.show, { id });
    navigate(path);
  };

  return (
    <>
    <Dashboard>
      <Grid container paddingY={2} marginTop={5} alignItems='center'>
        <Grid item xs={6}>
          <Typography variant="h5">Messages</Typography>
        </Grid>
        <Grid item xs={6} display='flex' justifyContent='end'>
          
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={5} sx={{ display: 'flex', alignItems: 'center' }}>
          <ToggleButtonGroup
            color="primary"
            fullWidth
            value={filterStatus}
            exclusive
            onChange={(event: React.MouseEvent<HTMLElement>, value: string | null) => {
              if (value) {
                handleFilterStatusChange(value);
              }
            }}
            aria-label="Platform"
          >
            { toggleButtonStatuses.map((status) => (
              <ToggleButton
                key={status}
                value={status}
              >
                {toProperCase(status)}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={12} lg={7}>
          
        </Grid>
      </Grid>
      { loading ? <LoadingSpinner /> : <>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left" sx={{ fontWeight: 600 }}>{ filterStatus === 'Inbox' ? 'From' : 'Recipient' }</TableCell>
                <TableCell align="left" sx={{ fontWeight: 600 }}>Subject</TableCell>
                <TableCell align="left" sx={{ fontWeight: 600 }}>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {chats?.map((row) => {
                const messages = row.messages;

                const lastMessage = messages[messages.length - 1];
                const fontWeight = lastMessage && !lastMessage.read && filterStatus !== 'sent' ? 600 : 400;
                const chatMembers = row.participants.map((particpant) => `${particpant.firstName ?? ''} ${particpant.lastName ?? ''}`).join(', ')
                return (<TableRow
                  key={row.id}
                  sx={{ 
                    '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer'
                  }}
                  hover={true}
                  onClick={() => { navigateToMessage(row.id) }}
                >
                  <TableCell component="th" scope="row" sx={{ fontWeight }}>{
                    filterStatus === 'Inbox' ? lastMessage.senderName : chatMembers
                  }</TableCell>
                  <TableCell align="left" sx={{ fontWeight }}>{toProperCase(row?.subject ?? '')} - {lastMessage?.body?.slice(0, 75)}</TableCell>
                  <TableCell align="left" sx={{ fontWeight }}>{formatDateString(lastMessage?.createdAt ?? '')}</TableCell>
                </TableRow>)
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </> }
    </Dashboard>
  
  
    </>
  );
}

export default withCommonTools(Messages);
