import { useQuery } from "react-query";
import { fetchById } from "../api/requests";
import { API_V3_ALLIGATOR_URLS } from "../constants/api-urls";
import type { TripGroupDetailType } from "../types/tripGroup";

export const useFetchTripGroup = (config: { headers: { Authorization: string } }, id?: string, ) => {
  return useQuery<TripGroupDetailType>(
    ['TripGroup', id],
    async () => await fetchById(API_V3_ALLIGATOR_URLS.groupBooking.show, id, config),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
};