import React, { useEffect, useRef, useState } from 'react';
import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';
import DashboardLayout from '../../components/layouts/Dashboard/Dashboard';
import { Box, Button, Container, FormControl, FormControlLabel, FormHelperText, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack, Typography } from '@mui/material';
import type { SelectChangeEvent } from '@mui/material';
import { useFetchUser } from '../../hooks/fetchUser';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../constants/api-urls';
import { ROUTER_URLS } from '../../constants/router-urls';
import { PhoneNumber } from '../../components/atoms/PhoneInput/PhoneInput';
import type { E164Number } from '../../components/atoms/PhoneInput/PhoneInput';
import { TIME_ZONES_DROPDOWN_OPTIONS } from '../../constants/settings';
import type { SettingTimeZoneType } from '../../types/setting';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { dateFromISOString, updateTimeToCurrent } from '../../utils/dates';
import { phoneNumberForInput } from '../../utils/phoneNumber';
import type { IReadFile } from '../../types/readFile';
import { computeChecksumMd5, uploadToS3 } from '../../utils/fileUpload';
// import { getCroppedImg } from '../../utils/cropImage';
import FileUploadInput from '../../components/atoms/FileUploadInput/FileUploadInput';
// import RoundImageCrop from '../../components/atoms/ImageCrop/ImageCrop';

interface IProps extends WrappedProps {};

function UserEditAccount({ getConfig, postConfig, getCurrentUserId }: IProps) {
  const navigate = useNavigate();

  const [phoneNumber, setPhoneNumber] = useState<E164Number>("");
  const [timeZone, setTimeZone] = useState(TIME_ZONES_DROPDOWN_OPTIONS[0].id);
  const [dateOfBirth, setDateOfBirth] = useState<Date | null>(new Date());
  const [gender, setGender] = useState('');

  const [uploadedImages, setUploadedImages] = useState<IReadFile[]>([]);
  // const [croppedAreaPixels, setCroppedAreaPixels] = React.useState<any>(null);
  const imagesToUploadCount = useRef(0);

  const [isSaving, setIsSaving] = useState(false);

  const { data: userRecord, isLoading } = useFetchUser(getConfig);

  useEffect(() => {
    if (userRecord) {
      if (userRecord.phoneNumber) {
        const phone = phoneNumberForInput(userRecord.phoneNumber);
        setPhoneNumber(phone);
      }
      setTimeZone(userRecord.timeZone);
      const formattedDateOfBirth = dateFromISOString(userRecord.dateOfBirth)
      setDateOfBirth(formattedDateOfBirth);
      setGender(userRecord.gender?.toLowerCase() ?? '');
    }
  }, [userRecord]);

  if (!userRecord) {
    return <DashboardLayout loading />;
  }

  const handlePhoneNumberChange = (event?: E164Number | undefined) => {
    setPhoneNumber(event ?? "");
  }

  const handleTimeZoneChange = (event: SelectChangeEvent) => {
    setTimeZone(event.target.value);
  };

  const handleBack = () => {
    navigate(ROUTER_URLS.user.profile);
  }

  const readFile = async (file: File) => {
    try {
      return await new Promise((resolve) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => { resolve(reader.result) }, false)
        reader.readAsDataURL(file)
      })
    } catch (error) {
      console.log(error);
    }
  }

  const saveAfterUpload = async (file: File) => {
    try {
      const imageDataUrl = await readFile(file).catch((e) => { console.log(e) }) as string;
      if (imageDataUrl) {
        // doesnt work with files since they are non enumerable
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const readImage = {
          publicUrl: imageDataUrl,
          loadedFile: file,
        } as any
        setUploadedImages([readImage]);
      }
    } catch(e) {
      console.log(e);
    }
  };

  const getS3Url = async (newFile: File) => {
    const checkSum = await computeChecksumMd5(newFile);
    return await axios.post(
      API_V3_ALLIGATOR_URLS.directUpload.create,
      {
        blob: {
          filename: newFile.name,
          content_type: newFile.type,
          byte_size: newFile.size,
          checksum: checkSum, /* base 64 of the MD5 hash of the file */
        }
      },
      {
        headers: { Authorization: postConfig.headers.Authorization },
      }
    )
  };

  const saveImage = async (): Promise<string | undefined> => {
    try {
      // const croppedImage = await getCroppedImg(
      //   uploadedImages[0].publicUrl ?? '',
      //   croppedAreaPixels,
      // ) as Blob;
      // const newImage = new File([uploadedImages[0].publicUrl], 'profilePicture');
      const uploadedImage = uploadedImages[0] as any
      const loadedFile = uploadedImage.loadedFile;
      if (loadedFile) {
        const s3UrlRes = await getS3Url(loadedFile);
  
        if (s3UrlRes.data.direct_upload) {
          const directUpload = s3UrlRes.data.direct_upload;
  
          const uploadToS3Res = await uploadToS3(directUpload.url, directUpload.headers, loadedFile)
          if (uploadToS3Res.status === 200) {
            return s3UrlRes.data.signed_id;
          }             
        }
      }
    } catch (e) {
      console.error(e)
      return undefined;
    }
  }

  const handleSubmit = (): void => {
    setIsSaving(true);
    const params: any = {
      user: {
        phone_number: phoneNumber,
        time_zone: timeZone,
        date_of_birth: dateOfBirth,
        gender,
      }
    }

    saveImage().then((signedId) => {
      if (signedId && signedId.length > 0) {
        params.user.business_logo = signedId
      }
      axios.patch(
        API_V3_ALLIGATOR_URLS.users.updateProfile,
        params,
        getConfig,
      ).then(() => {
        navigate(ROUTER_URLS.user.profile);
      }).catch((e) => {
        console.log(e)
        setIsSaving(false)
      })
    }).catch((e) => {
      console.log(e);
      setIsSaving(false)
    });
  };

  return (
    <DashboardLayout loading={isLoading || isSaving}>
      <Container component={Stack} maxWidth="lg" my={6}>
        <Typography component="h1" variant="h5" my={3}>
          Edit Account Details
        </Typography>
        <Box sx={{ my: 3 }}>
          <PhoneNumber
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            label="Contact Phone Number"
            helperText=""
          />
        </Box>
        <Box sx={{ my: 3 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Date of Birth"
              value={dateOfBirth}
              onChange={(newValue) => {
                updateTimeToCurrent(newValue)
                setDateOfBirth(newValue)
              }}
              sx={{ width: '100%' }}
            />
          </LocalizationProvider>
        </Box>
        <Box sx={{ my: 3 }}>
          <FormControl>
            <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={gender}
              onChange={(e) => { setGender(e.target.value)}}
            >
              <FormControlLabel value="male" control={<Radio />} label="Male" />
              <FormControlLabel value="female" control={<Radio />} label="Female" />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box sx={{ my: 3 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-helper-label">Time Zone</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={timeZone}
              label="Time Zone"
              onChange={handleTimeZoneChange}
            >
              { TIME_ZONES_DROPDOWN_OPTIONS.map((option: SettingTimeZoneType) => (
                <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>
              ))}
            </Select>
            <FormHelperText>Please select your preffered time zone notification purposes</FormHelperText>
          </FormControl>
        </Box>
        <Box sx={{ my: 3 }}>
          <Typography component="p" variant="body1" marginTop={3}>
            Add your business logo
          </Typography>
          {uploadedImages.length === 0 && 
            <FileUploadInput
              promptText='Drag and drop your business logo'
              buttonText='Upload Photo'
              uploadedFiles={uploadedImages}
              setUploadedFiles={setUploadedImages}
              postConfig={postConfig}
              filesToUploadCount={imagesToUploadCount}
              saveToApi={saveAfterUpload}
            />}
          {
            // uploadedImages.length > 0 &&
            // <RoundImageCrop
            //   uploadedImage={uploadedImages[0]}
            //   imgSrc={userRecord.avatarUrl ?? ''}
            //   setCroppedAreaPixels={setCroppedAreaPixels}
            // />
            uploadedImages.length > 0 && <img
              src={uploadedImages[0].publicUrl}
              style={{ height: '100%', width: '100%', objectFit: 'cover' }}
            />
          }
        </Box>

        <Box display='flex' flexDirection='row' justifyContent='flex-end'>
          <Button variant="outlined" onClick={handleBack}>Cancel</Button>
          <Button variant="contained" onClick={handleSubmit} sx={{ mx: 2 }}>Save</Button>
        </Box>
      </Container>
    </DashboardLayout>
  );
}

export default withCommonTools(UserEditAccount);