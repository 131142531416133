import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Fab, FormControl, FormHelperText, Grid, MenuItem, Select, ToggleButton, ToggleButtonGroup, Typography, useMediaQuery, useTheme} from '@mui/material';
import type { SelectChangeEvent } from '@mui/material';
import Dashboard from '../../components/layouts/Dashboard/Dashboard';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../constants/api-urls';
import { dateFromISOString } from '../../utils/dates';
import { addDays, eachDayOfInterval, format, subDays } from 'date-fns';
import type { BookingResultType } from '../../types/booking';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import listPlugin from '@fullcalendar/list';
import type { EventClickArg, EventInput } from '@fullcalendar/core';
import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';
import { useFetchListings } from '../../hooks/fetchListing';
import AddIcon from '@mui/icons-material/Add';

import CalendarTripModal from '../../components/compounds/CalendarTripModal/CalendarTripModal';
import { ROUTER_URLS } from '../../constants/router-urls';
import ChangeColorModal from '../../components/compounds/ChangeColorModal/ChangeColorModal';
import type { ListingDetailType } from '../../types/listing';
import { listingDisplayName } from '../../utils/listing';
import './styling.scss';
import { buildLongTitle, buildShortTitle } from '../../utils/calendar';
import type { TripGroupType } from '../../types/tripGroup';

interface IProps extends WrappedProps {};

const calendarViewOptions = [
  { id: 'dayGridMonth', label: 'Month' },
  { id: 'listMonth', label: 'List' },
  { id: 'availability', label: '# Hunters'},
];

const mapCalendarEvents = (tripGroups: TripGroupType[], bookings: BookingResultType[]): EventInput[] => {
  const allGroupedTripIds = tripGroups.map((group) => group.tripIds).flat();
  const calendarEvents = tripGroups.map((tripGroup) => {
    let startDate = dateFromISOString(tripGroup.groupAttributes.startDate);
    // we add one day bc we need the calendar to show the last day as having an event on it
    let endDate = addDays(dateFromISOString(tripGroup.groupAttributes.endDate), 1);

    const arriveDaysBefore = tripGroup.groupAttributes.arriveDaysBefore > 0;
    const departDaysAfter = tripGroup.groupAttributes.departDaysAfter > 0;

    if (arriveDaysBefore) {
      startDate = subDays(startDate, 1);
    }
    if (departDaysAfter) {
      endDate = addDays(endDate, 1);
    }
    
    const formattedStartDate = format(startDate, 'yyyy-MM-dd');
    const formattedEndDate = format(endDate, 'yyyy-MM-dd');

    return {
      title: tripGroup.calendarLabel,
      start: formattedStartDate,
      end: formattedEndDate,
      color: tripGroup.hexColor,
      extendedProps: {
        longTitle: tripGroup.calendarLabel,
        id: tripGroup.id,
        type: 'tripGroup',
        arriveDaysBefore,
        departDaysAfter,
      }
    };
  });
  const tripEvents = bookings.filter((booking) => { 
      return !['denied', 'cancelled', 'added_to_cart'].includes(booking.status) && !allGroupedTripIds.includes(booking.id)
    }).map((booking) => {
    let startDate = dateFromISOString(booking.startDate);
    // we add one day bc we need the calendar to show the last day as having an event on it
    let endDate = addDays(dateFromISOString(booking.endDate), 1);

    const arriveDaysBefore = booking.arriveDaysBefore > 0;
    const departDaysAfter = booking.departDaysAfter > 0;


    if (arriveDaysBefore) {
      startDate = subDays(startDate, 1);
    }
    if (departDaysAfter) {
      endDate = addDays(endDate, 1);
    }
    
    const formattedStartDate = format(startDate, 'yyyy-MM-dd');
    const formattedEndDate = format(endDate, 'yyyy-MM-dd');

    const shortTitle = buildShortTitle(booking);
    const longTitle = buildLongTitle(booking);

    return {
      title: shortTitle,
      start: formattedStartDate,
      end: formattedEndDate,
      color: booking.listing.hexColor,
      extendedProps: {
        longTitle,
        id: booking.id,
        type: 'booking',
        arriveDaysBefore,
        departDaysAfter,
      }
    };
  });

  return calendarEvents.concat(tripEvents);
}

interface SimpleBookingType {
  title: number,
  start: Date,
  color: string;
};

const mapAvailability = (bookings: BookingResultType[]): EventInput[] => {
  const tripEvents = bookings.filter((booking) => { 
    return !['denied', 'cancelled', 'added_to_cart'].includes(booking.status)
  });

  const expandedBookings: SimpleBookingType[] = [];
  for (let index = 0; index < tripEvents.length; index++) {
    const element = tripEvents[index];
    let startDate = dateFromISOString(element.startDate);
    let endDate = dateFromISOString(element.endDate)
    const arriveDaysBefore = element.arriveDaysBefore > 0;
    const departDaysAfter = element.departDaysAfter > 0;
    if (arriveDaysBefore) {
      startDate = subDays(startDate, 1);
    }
    if (departDaysAfter) {
      endDate = addDays(endDate, 1);
    }

    const range = eachDayOfInterval({
      start: startDate,
      end: endDate,
    });

    for (let rangeIndex = 0; rangeIndex < range.length; rangeIndex++) {
      const day = range[rangeIndex];
      expandedBookings.push({
        title: element.numberOfGuests,
        start: day,
        color: '#009688',
      })
    }
  }

  const groupedBookings = expandedBookings.reduce((accumulator: any, current) => {
    const group = format(current.start, 'yyyy-MM-dd');
    if (!accumulator[group]) {
      accumulator[group] = { start: group, color: current.color, title: 0 };
    }
    const count = Number(accumulator[group].title) + Number(current.title)
    accumulator[group].title = count.toString();
    ;
    return accumulator;
  }, {});
  
  const countsByDay = Object.values(groupedBookings);
  return countsByDay as unknown as EventInput[];
}

const mobileHeaderConfig = {
  left: 'prev',
  center: 'title',
  right: 'next',
}

const desktopHeaderConfig = {
  left: 'prev,next today',
  center: 'title',
  right: 'availabilityButton dayGridMonth,dayGridWeek,listMonth' // user can switch between the two
}

function Calendar({ postConfig, getConfig }: IProps) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const [selectedListingId, setSelectedListingId] = useState<string>('');
  const [selectedListingName, setSelectedListingName] = useState<string>('');
  // const [calendarBookings, setCalendarBookings] = useState<EventInput[]>([]);
  const [calendarEvents, setCalendarEvents] = useState<EventInput[]>([]);
  const [availabilityEvents, setAvailabilityEvents] = useState<EventInput[]>([]);
  const [calendarView, setCalendarView] = useState<string>('dayGridMonth');
  const [hexColor, setHexColor] = useState<string>('');
  const [nickname, setNickname] = useState('');

  const [showAvailability, setShowAvailability] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [colorOpen, setColorOpen] = React.useState(false);
  const [colorLoading, setColorLoading] = useState(false);

  const [selectedEventId, setSelectedEventId] = useState<string>();
  const [selectedEventType, setSelectedEventType] = useState<string>();

  const [timeStamp, setTimeStamp] = useState(new Date().getTime())

  useEffect(() => {
    const handleHeaderNavClick = () => {
      setShowAvailability(false);
    }
    const headerNavClasses = ['.fc-dayGridMonth-button', '.fc-dayGridWeek-button', '.fc-listMonth-button'];
    headerNavClasses.forEach((navClass) => {
      const element = document.querySelector(navClass);
      if (element) {
        element.addEventListener('click', handleHeaderNavClick)
  
        return () => {
          element.removeEventListener('click', handleHeaderNavClick);
        };
      }
    });
  }, []);

  const handleClose = () => {
    setOpen(false);
    setSelectedEventId(undefined);
    setSelectedEventType(undefined);
    setTimeStamp(new Date().getTime());
  };

  const handleColorClose = () => {
    setColorOpen(false);
    // setSelectedTripId(undefined);
    // setTimeStamp(new Date().getTime());
  };

  const calendarRef = useRef<FullCalendar>(null)
  
  const { data: listingRecords, isLoading, refetch: reload } = useFetchListings(postConfig);

  useEffect(() => {
    let isMounted = true;

    const getEvents = async () => {
      if (!isMounted) {
        return;
      }
      
      try {
        const { data: groupTrips } = await axios.get(API_V3_ALLIGATOR_URLS.groupBooking.list, getConfig);
        const { data: trips } = await axios.post(API_V3_ALLIGATOR_URLS.booking.list, { trip: { status: '', listing_id: selectedListingId }}, postConfig)
        const mappedCalendarEvents = mapCalendarEvents(groupTrips, trips);
        setCalendarEvents(mappedCalendarEvents);

        const mappedAvailability = mapAvailability(trips);
        setAvailabilityEvents(mappedAvailability);
      } catch (error) {
        console.error(error);
      }
    };

    getEvents().catch((e) => { console.log(e) });

    return () => {
      isMounted = false;
    };
  }, [timeStamp, selectedListingId]);

  // const navigateToBooking = (id: number) => {
  //   const path = generatePath(ROUTER_URLS.bookings.show, { id });
  //   navigate(path);
  // };

  const handleEventClick = (info: EventClickArg) => {
    if (showAvailability) return;

    setOpen(true);
    setSelectedEventId(info.event.extendedProps.id);
    setSelectedEventType(info.event.extendedProps.type);
  };

  const handleListingSelectChange = (event: SelectChangeEvent) => {
    const id = event.target.value;
    setSelectedListingId(id);
    
    if (listingRecords) {
      const option = listingRecords.find((record) => record.id.toString() === id);
      if (option) {
        setSelectedListingName(listingDisplayName(option));  
      }
    }
  };

  const handleCalendarViewChange = (selectedOption: string) => {
    setCalendarView(selectedOption);
    let view = selectedOption;
    const showCounts = selectedOption === 'availability';
    if (showCounts) {
      view = 'dayGridMonth';
    } 
    setShowAvailability(showCounts);
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi()
      calendarApi.changeView(view);
    }
  };

  const handleSave = () => {
    setColorLoading(true);
    if (selectedListingId) {
      const params = {
        listing: { hex_color: hexColor, host_app_display_name: nickname }
      };

      axios.patch(`${API_V3_ALLIGATOR_URLS.listing.update}${selectedListingId}`, params, postConfig).then((response) => {
        const newListingData: ListingDetailType = response.data;
        if (newListingData.id) {
          setColorLoading(false);
          if (colorOpen) {
            setTimeStamp(new Date().getTime());
            reload().catch((e) => { console.log(e) });
            handleColorClose()
          }
        } else {
          console.log('error failed to save');
        }
      }).catch((error) => {
        console.log(error);
      });
    }
  };

  const calendarData = () => {
    return showAvailability ? availabilityEvents : calendarEvents;
  }

  const headerConfig = !matches ? mobileHeaderConfig : desktopHeaderConfig;

  return (
    <Dashboard loading={colorLoading}>
      <Grid container marginTop={3} marginBottom={10} spacing={3} sx={{ minHeight: '450px' }}>
        <Grid item xs={12} sm={6}>
          { !isLoading &&
            <FormControl fullWidth>
              <Select
                labelId="listing-label"
                id="listing-select"
                value={selectedListingId}
                onChange={handleListingSelectChange}
                fullWidth
                placeholder='All Packages'
                displayEmpty
              >
                <MenuItem value="">
                  All Packages
                </MenuItem>
                {listingRecords && listingRecords.length > 0 && listingRecords.map((listing) => {
                  return (
                    <MenuItem key={listing.id} value={listing.id.toString()}>
                      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                        <Box sx={{ backgroundColor: listing.hexColor, height: '20px', width: '20px', borderRadius: 1 }} /><Typography>{listingDisplayName(listing)}</Typography>
                      </Box>
                    </MenuItem>
                  )
                })}
              </Select>
              {!selectedListingId && <FormHelperText>Select a package to change the color</FormHelperText>}
            </FormControl>
          }
          {selectedListingId && <Button variant='text' onClick={() => { setColorOpen(true) }} sx={{ textTransform: 'none' }}>Change color or name</Button>}
        </Grid>
        {matches && <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', alignContent: 'center' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Button href={ROUTER_URLS.bookings.new} startIcon={<AddIcon />} variant='contained' color='secondary'>
              Add Trip
            </Button>
          </Box>
        </Grid>}
        { !matches &&
          <Grid item xs={12} lg={5} sx={{ display: 'flex', alignItems: 'center' }}>
            <ToggleButtonGroup
              color="primary"
              fullWidth
              value={calendarView}
              exclusive
              onChange={(event: React.MouseEvent<HTMLElement>, value: string | null) => {
                if (value) {
                  handleCalendarViewChange(value);
                }
              }}
              aria-label="Platform"
            >
              { calendarViewOptions.map((option) => (
                <ToggleButton
                  key={option.id}
                  value={option.id}
                  
                >
                  {option.label}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Grid>
        }
        <Grid item xs={12} sx={{ minHeight: '450px' }}>
          <FullCalendar
            ref={calendarRef}
            plugins={[ dayGridPlugin, listPlugin ]}
            initialView=""
            // events={() => { return calendarData() }}
            eventSources={[
              // your event source
              {
                events: function(fetchInfo, successCallback, failureCallback) {
                  // req.get('myxmlfeed.php')
                  // .type('xml')
                  // .query({
                  //   start: info.start.valueOf(),
                  //   end: info.end.valueOf()
                  // })
                  // .end(function(err, res) {
// 
                  //   if (err) {
                  //     failureCallback(err);
                  //   } else {
// 
                  //     successCallback(
                  //       Array.prototype.slice.call( // convert to array
                  //         res.getElementsByTagName('event')
                  //       ).map(function(eventEl) {
                  //         return {
                  //           title: eventEl.getAttribute('title'),
                  //           start: eventEl.getAttribute('start')
                  //         }
                  //       })
                  //     )
                  //   }
                  // })
                  successCallback(
                    calendarData()
                  )
                },
                // color: 'yellow',   // an option!
                // textColor: 'black' // an option!
              }
              // any other sources...
            ]}
            contentHeight='auto'
            height='auto'
            eventClick={handleEventClick}

            headerToolbar={headerConfig}
            customButtons={{
              availabilityButton: {
                text: showAvailability ? 'details view' : '# of hunters',
                click: function() {
                  setShowAvailability(!showAvailability);
                }
              }
            }}
            dayMaxEventRows={true}
            views={{
              dayGridMonth: {
                dayMaxEventRows: 2
              },
            }}
            eventDidMount={(info) => {

              var timeTableCell = info.el.getElementsByClassName('fc-list-event-time') as HTMLCollectionOf<HTMLElement>;
              if (timeTableCell[0]) {
                timeTableCell[0].style.display = 'none'
              }
              var titleTableCell = info.el.getElementsByClassName('fc-list-event-title') as HTMLCollectionOf<HTMLElement>;
              if (titleTableCell[0]) {
                titleTableCell[0].innerText = info.event.extendedProps.longTitle
              }
            }}

            eventClassNames={(arg) => {
              const eventClasses = [];

              if (arg.event.extendedProps.arriveDaysBefore) {
                eventClasses.push('fc-event-pad-half-day-start');
              }
              if (arg.event.extendedProps.departDaysAfter) {
                eventClasses.push('fc-event-pad-half-day-end');
              }
              if (showAvailability) {
                eventClasses.push('fc-event-show-availability');
              }
              return eventClasses;
            }}
            eventContent={(arg) => {
              if (!showAvailability) return true;

              return (
                <Box sx={{ textAlign: 'center' }}>
                  <Typography fontSize={20} fontWeight={600}>{arg.event.title}</Typography>
                </Box>
              )
            }}
          />
        </Grid>
        {!matches && <Box sx={{ '& > :not(style)': { m: 1 } }}>
          <Fab
            color="secondary"
            aria-label="add"
            sx={{
              position: "fixed",
              bottom: (theme) => theme.spacing(2),
              right: (theme) => theme.spacing(2)
            }}
            href={ROUTER_URLS.bookings.new}
          >
            <AddIcon />
          </Fab>
        </Box>}
      </Grid>
      <div>
        <CalendarTripModal
          open={open}
          onClose={handleClose}
          eventId={selectedEventId}
          eventType={selectedEventType}
          getConfig={getConfig}
        />
      </div>
      <div>
        { selectedListingId &&
          <ChangeColorModal
            listingId={selectedListingId}
            listingName={selectedListingName}
            listingColor={hexColor}
            setListingColor={setHexColor}
            onClose={handleColorClose}
            open={colorOpen}
            getConfig={getConfig}
            handleSave={handleSave}
            nickname={nickname}
            setNickname={setNickname}
          />
        }
      </div>
    </Dashboard>
  );
}

export default withCommonTools(Calendar);
