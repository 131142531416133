import type { SettingTimeZoneType } from "../types/setting";

export const TIME_ZONES_DROPDOWN_OPTIONS: SettingTimeZoneType[] = [
  { id: 'America/Chicago', label: 'Central Time'  },
  { id: 'America/New_York', label: 'Eastern Time'  },
  { id: 'America/Denver', label: 'Mountain Time' },
  { id: 'America/Los_Angeles', label: 'Pacific Time' },
  { id: 'America/Juneau', label: 'Alaska Time' },
  { id: 'Pacific/Honolulu', label: 'Hawaii-Aleutian Time' },
  { id: 'Atlantic/Faroe', label: 'Other' },
];

export const ADMIN_USER = 'admin';
export const REFERRAL_USER = 'referral';
export const HOST_USER = 'host';
export const HOST_AND_REFERRAL_USER = 'hostAndReferral'
export const REGULAR_USER = 'user';
export const SALES_USER = 'sales'
export const GUIDE_USER = 'guide';
export const HOST_AUXILLARY = 'hostAuxillary'

export const ROLES = [
  REGULAR_USER,
  HOST_USER,
  ADMIN_USER,
  REFERRAL_USER,
  HOST_AND_REFERRAL_USER,
  SALES_USER,
  GUIDE_USER,
  HOST_AUXILLARY,
];

export const FEATURE_FLAGS = {
  contactSupport: false,
}