import React from 'react';
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { withCommonTools } from '../../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../../components/compounds/CommonWrapper/withCommonTools';
import Dashboard from '../../../components/layouts/Dashboard/Dashboard';
import LoadingSpinner from '../../../components/atoms/LoadingSpinner/LoadingSpinner';

import { useFetchAdminInvoices } from '../../../hooks/fetchInvoice';

interface IProps extends WrappedProps {};

function AdminWaivers({ getConfig, postConfig }: IProps) {
  // const [open, setOpen] = useState(false);

  const { data: invoices, isLoading } = useFetchAdminInvoices(getConfig);

  // const handleClose = () => {
  //   setOpen(false)
  // }

  return (
    <Dashboard>
      <Grid container paddingY={2} marginTop={5} alignItems='center'>
        <Grid item xs={6}>
          <Typography variant="h5">Invoices</Typography>
        </Grid>
        <Grid item xs={6} display='flex' justifyContent='end'>
          
            {
              // <Button
              //   type="submit"
              //   variant="contained"
              //   onClick={() => {
              //     setOpen(true);
              //   }}
              //   color='primary'
              //   sx={{ marginRight: 2 }}
              // >
              //   Send Invoice
              // </Button>
            }
        </Grid>
      </Grid>
      { isLoading ? <LoadingSpinner /> : <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Title</TableCell>
              <TableCell align="left">Sent To</TableCell>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left">Phone Number</TableCell>
              <TableCell align="left">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices?.map((row) => {
              return (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  hover={true}
                  
                >
                  <TableCell align="left">{row.id}</TableCell>
                  <TableCell align="left">{row.payerTotal}</TableCell>
                  <TableCell align="left">{row.invoiceNumber}</TableCell>
                </TableRow>
              )}
            )}
          </TableBody>
        </Table>
      </TableContainer>}
      
    </Dashboard>
  );
}

export default withCommonTools(AdminWaivers);