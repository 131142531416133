import { Box, FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useState } from "react";
import { ROUTER_URLS } from "../../../constants/router-urls";
import { useFetchDashboardUpcomingBookings } from "../../../hooks/fetchDashboard";
import CustomLink from "../../atoms/CustomLink/CustomLink";
import SignificantNumberCard from "../SignificantNumberCard/SignificantNumberCard";

interface IProps {
  getConfig: {
    headers: {
        Authorization: string;
    };
  }
}

export default function StaffUpcomingBookingsCard({ getConfig }: IProps) {

  const [daysFilter, setDaysFilter] = useState('7')

  const { data: dashboardMetrics, isLoading: dashboardMetricsLoading } = useFetchDashboardUpcomingBookings(getConfig, { days_filter: daysFilter });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDaysFilter((event.target as HTMLInputElement).value);
  };

  return (
    <SignificantNumberCard
      header="Upcoming Hunts"
      loading={dashboardMetricsLoading}
      count={dashboardMetrics?.total ?? 0}
      variant='h2'
      navLink={
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          justifyItems: 'center',
          borderTop: 1,
          borderColor: 'divider',
          width: '100%',
          paddingY: 2 
        }}>
          <CustomLink to={ROUTER_URLS.bookings.index} text="View All Hunts" />
        </Box>
      }
      toggles={
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={daysFilter}
            onChange={handleChange}
            row
          >
            <FormControlLabel value="7" control={<Radio />} label="Next 7 Days" />
            <FormControlLabel value="30" control={<Radio />} label="Next 30 Days" />
          </RadioGroup>
        </FormControl>
      }
    />
  );
}
