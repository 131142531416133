import React, { useEffect, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Button, Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';

import DashboardLayout from '../../../components/layouts/Dashboard/Dashboard';

import { useFetchListing } from '../../../hooks/fetchListing';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { ROUTER_URLS } from '../../../constants/router-urls';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../../constants/api-urls';
import { dateFromISOString } from '../../../utils/dates';
import { PhoneNumber } from '../../../components/atoms/PhoneInput/PhoneInput';
import type { E164Number } from '../../../components/atoms/PhoneInput/PhoneInput';

import { withCommonTools } from '../../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../../components/compounds/CommonWrapper/withCommonTools';

interface IProps extends WrappedProps {};

function AdminEditListingAvailability({ getConfig, postConfig }: IProps) {
  const { id: listingId } = useParams();
  const navigate = useNavigate();

  const [checkInTime, setCheckIntime] = useState<Date | null>(new Date());
  const [checkOutTime, setCheckOutTime] = useState<Date | null>(new Date());
  const [limitNumberOfGuests, setLimitNumberOfGuests] = useState(false);
  const [minimumNumberOfGuests, setMinimumNumberOfGuests] = useState("0");
  const [maximumNumberOfGuests, setMaximumNumberOfGuests] = useState("0");
  const [phoneNumber, setPhoneNumber] = useState<E164Number>("");
  const [isSaving, setIsSaving] = useState(false);

  const { data: listingRecord, isLoading } = useFetchListing(getConfig, listingId ?? '');

  useEffect(() => {
    if (listingRecord) {
      setCheckIntime(dateFromISOString(listingRecord.checkInTime ?? ''));
      setCheckOutTime(dateFromISOString(listingRecord.checkOutTime ?? ''));
      setLimitNumberOfGuests(listingRecord.enforceAccomodationRange);
      setMinimumNumberOfGuests(listingRecord.accommodateMin?.toString() ?? '');
      setMaximumNumberOfGuests(listingRecord.accommodateMax?.toString() ?? '');
      setPhoneNumber(listingRecord.contactNumber);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [listingRecord])

  const navigateToListing = () => {
    const path = generatePath(ROUTER_URLS.admin.listings.show, { id: listingId });
    navigate(path);
  };

  const handleSave = () => {
    setIsSaving(true);

    const params = {
      listing: {
        check_in_time: checkInTime,
        check_out_time: checkOutTime,
        enforce_accomodation_range: limitNumberOfGuests,
        accommodate_min: minimumNumberOfGuests,
        accommodate_max: maximumNumberOfGuests,
        contact_number: phoneNumber,
        cancellation_policy_id: 4,
        published: true,
      }
    };

    axios.patch(`${API_V3_ALLIGATOR_URLS.listing.update}${listingId ?? ''}`, params, postConfig).then((response) => {
      navigateToListing();
    }).catch((error) => {
      console.log(error);
    });
  };

  const handleCancel = () => {
    navigateToListing();
  }

  const handleLimitNumberOfGuestsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLimitNumberOfGuests(event.target.checked);
  };

  const handlePhoneNumberChange = (event?: E164Number | undefined) => {
    setPhoneNumber(event ?? "");
  }

  // @todo: make this look nice with a link back to the listings page.
  // this should be returned if the listing id is null empty or undefined as well
  if (!listingRecord) {
    return <DashboardLayout loading />;
  }

  return (
    <DashboardLayout loading={isLoading || isSaving}>
      <Grid container direction='row' padding={2} spacing={2}>
        <Grid item xs={12} lg={6} marginTop={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container direction='row' paddingY={2} spacing={2}>
              <Grid item xs={12} lg={5}>
                <TimePicker
                  label="Check In Time"
                  value={checkInTime}
                  onChange={(newValue) => { setCheckIntime(newValue) }}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={12} lg={5}>
                <TimePicker
                  label="Check Out Time"
                  value={checkOutTime}
                  onChange={(newValue) => { setCheckOutTime(newValue) }}
                  sx={{ width: '100%' }}
                />
              </Grid>
            </Grid>
            <Grid container direction='row' spacing={2}>
              <Grid item xs={12} lg={5}>
                <FormControlLabel control={<Checkbox
                  checked={limitNumberOfGuests}
                  onChange={handleLimitNumberOfGuestsChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />} label="Limit number of hunters" />
              </Grid>
            </Grid>
            {
              limitNumberOfGuests && (
                <Grid container direction='row' paddingY={2} spacing={2}>
                  <Grid item xs={12} lg={5}>
                    <TextField
                      id="outlined-number"
                      value={minimumNumberOfGuests}
                      onChange={(event) => { setMinimumNumberOfGuests(event.target.value)}}
                      label="Minimum"
                      type="number"
                      fullWidth
                      helperText="Minimum number of hunters"
                    />
                  </Grid>
                  <Grid item xs={12} lg={5}>
                    <TextField
                      id="outlined-number"
                      value={maximumNumberOfGuests}
                      onChange={(event) => { setMaximumNumberOfGuests(event.target.value)}}
                      label="Maximum"
                      type="number"
                      fullWidth
                      helperText="Maximum number of hunters"
                    />
                  </Grid>
                </Grid>
              )
            }
            <Grid container direction='row' paddingY={2} spacing={2}>
              <Grid item xs={12} lg={10}>
                <PhoneNumber
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  label="Contact Phone Number"
                  helperText="What is a good phone number to contact you regarding this listing?"
                />
              </Grid>
            </Grid>
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Grid item sx={{ display: 'flex', justifyContent: 'end' }}>
        <Button
          color='primary'
          variant='outlined'
          onClick={handleCancel}
          sx={{ mr: 1 }}
        >
          Cancel
        </Button>
        <Button onClick={handleSave} variant='contained' color='primary'>
          Save
        </Button>
      </Grid>
    </DashboardLayout>
  );
}

export default withCommonTools(AdminEditListingAvailability);
