import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../components/layouts/Dashboard/Dashboard';
import { useParams } from 'react-router-dom';
import { useFetchBooking } from '../../hooks/fetchBooking';
import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';

import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../constants/api-urls';
import { Alert, Grid, Stack } from '@mui/material';
import BookingListingCard from '../../components/compounds/BookingCard/BookingListingCard';
import BookingFeeDetailsCard from '../../components/compounds/BookingCard/BookingFeeDetailsCard';
import BookingUserCard from '../../components/compounds/BookingCard/BookingUserCard';
import BookingPayoutCard from '../../components/compounds/BookingCard/BookingPayoutCard';
import { useFetchUser } from '../../hooks/fetchUser';
import TripWorkflowStatusPoller from '../../components/compounds/BookingCard/TripWorkflowStatusPoller';
import { useUserCanAccessBookNow } from '../../hooks/userCanAccess';
import BackButton from '../../components/compounds/BackButton/BackButton';

interface IProps extends WrappedProps {};

function ShowBooking({ getConfig, postConfig, getCurrentUserId }: IProps) {
  const { id: bookingId } = useParams();
  const { data: bookingRecord, isLoading, refetch } = useFetchBooking(getConfig, bookingId ?? '');

  const userId = getCurrentUserId();
  const canAccessBookNow = useUserCanAccessBookNow();
  const { data: userRecord, isLoading: userIsLoading } = useFetchUser(getConfig);

  const [feeLoading, setFeeLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [pollAccepting, setPollAccepting] = useState(false);
  const [pollDenying, setPollDenying] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [successMessage, setSuccessMessage] = useState<string>();

  const [usageFee, setUsageFee] = useState<number>(5);

  useEffect(() => {
    const params = {
      app_fee: {
        source: 'marketplace',
      }
    }
    
    if (canAccessBookNow) {
      params.app_fee.source = 'book_now';
    }
    setFeeLoading(true);
    axios.post(API_V3_ALLIGATOR_URLS.appUsageFees.search, params, getConfig).then((response) => {
      setUsageFee(response.data.usageFee);
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      setFeeLoading(false);
    });
  }, [])

  if (!bookingRecord) {
    return <DashboardLayout loading />;
  }

  const postApproveTrip = () => {
    refetch().catch((e) => { console.log(e) }).finally(() => {
      setSuccessMessage('Hunt approved!');
      setPollAccepting(false);
      setActionLoading(false);
    });
  }

  const postDenyTrip = () => {
    refetch().catch((e) => { console.log(e) }).finally(() => {
      setSuccessMessage('Hunt denied.');
      setPollDenying(false);
      setActionLoading(false);
    });
  }

  const approveBooking = () => {
    setActionLoading(true);
    const params = {
      // bookingId: bookingRecord.id,
      // acceptBooking: true,
      // responseNote: '',
    };
    axios.post(`${API_V3_ALLIGATOR_URLS.booking.confirm}${bookingId ?? ''}`, params, postConfig).then((response) => {
      setPollAccepting(true);
    }).catch((e) => {
      console.log(e);
      setErrorMessage('Failed to approve hunt. Please contact support');
      setActionLoading(false);
    });
  };

  const denyBooking = () => {
    const params = {
      // bookingId: bookingRecord.id,
      // acceptBooking: false,
      // responseNote: '',
    };
    axios.post(`${API_V3_ALLIGATOR_URLS.booking.deny}${bookingId ?? ''}`, params, postConfig).then((response) => {
      // setSuccessMessage('Hunt denied');
      // refetch().catch((e) => { console.log(e) });
      // setActionLoading(false);
      setPollDenying(true);
    }).catch((e) => {
      console.log(e);
      setErrorMessage('Failed to deny hunt. Please contact support');
      setActionLoading(false);
    });
  };

  const handleStripeConnectClick = () => {
    axios.post(API_V3_ALLIGATOR_URLS.stripeConnect.create, {}, getConfig).then((res) => {
      window.location.replace(res.data.stripe_link);
    }).catch((e) => {
      console.log(e);
    })
  }

  const bookingRequested = ['requested'].includes(bookingRecord.status.toLowerCase());
  const firstPaymentCompleted = bookingRecord.userPayments && bookingRecord.userPayments.filter((payment) => payment.status === 'completed').length > 0;
  const cardSizes = bookingRequested || !firstPaymentCompleted ? 6 : 4;
  const hasStripeConnected = Boolean(userRecord?.connectAccountSetup);

  return (
    <>
    { pollAccepting && bookingId && <TripWorkflowStatusPoller bookingId={bookingId} getConfig={getConfig} refetch={postApproveTrip} watchingStatuses={['approved']} />}
    { pollDenying && bookingId && <TripWorkflowStatusPoller bookingId={bookingId} getConfig={getConfig} refetch={postDenyTrip} watchingStatuses={['denied']} />}
    <DashboardLayout loading={isLoading || userIsLoading || actionLoading || feeLoading} errorMessage={errorMessage}>
      <BackButton />
      {successMessage && <Alert severity="success" sx={{ marginTop: 5 }}>{ successMessage }</Alert>}
      <Stack marginTop={2} marginBottom={5} spacing={3}>
        <Grid container direction='row' spacing={3} display='flex' alignItems="stretch">
          {!bookingRequested && firstPaymentCompleted && <Grid item xs={12} lg={cardSizes}>
            <BookingPayoutCard booking={bookingRecord} />
          </Grid>}
          <Grid item xs={12} lg={cardSizes}>
            <BookingListingCard booking={bookingRecord} />
          </Grid>
          {bookingRecord.user && <Grid item xs={12} lg={cardSizes}>
            <BookingUserCard user={bookingRecord.user} chatId={bookingRecord.chatId} status={bookingRecord.status} />
          </Grid>}
        </Grid>
        <Grid container direction='row'>
          <Grid item xs={12} lg={12}>
            <BookingFeeDetailsCard
              booking={bookingRecord}
              confirmBooking={approveBooking}
              denyBooking={denyBooking}
              hasStripeConnected={hasStripeConnected}
              handleStripeClick={handleStripeConnectClick}
              userId={userId}
              usageFee={usageFee}
            />
          </Grid>
        </Grid>
      </Stack>
    </DashboardLayout>
    </>
  );
}

export default withCommonTools(ShowBooking);
