import React, { useState } from 'react';
import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';
import DashboardLayout from '../../components/layouts/Dashboard/Dashboard';
import { Box, Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
// import type { SelectChangeEvent } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';

import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../constants/api-urls';
import { ROUTER_URLS } from '../../constants/router-urls';

// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
// import { dateFromISOString, updateTimeToCurrent } from '../../utils/dates';
// import { updateTimeToCurrent } from '../../utils/dates';

// import toProperCase from '../../utils/strings';
// import { useFetchListings } from '../../hooks/fetchListing';
// import { range } from 'lodash';
import { PhoneNumber } from '../../components/atoms/PhoneInput/PhoneInput';
import type { E164Number } from '../../components/atoms/PhoneInput/PhoneInput';
import theme from '../../theme/theme';
import NewInvoiceLineItemModal from '../../components/compounds/Invoice/NewInvoiceLineItemModal';
import LineItemActions from '../../components/compounds/Invoice/LineItemActions';
import SendIcon from '@mui/icons-material/Send';

interface IProps extends WrappedProps {};

// const BOOKING_STATUSES = [
//   { key: 'approved', label: 'Upcoming' },
//   { key: 'completed', label: 'Completed' },
//   { key: 'cancelled', label: 'Cancelled' }
// ];
interface ILineItems {
  id: number;
  name: string;
  description: string;
  quantity: string;
  unitCost: string;
  total: string;
}

function ccyFormat(num: number) {
  return `$${num.toFixed(2)}`;
}

function invoiceTotal(items: readonly ILineItems[]) {
  return items.map(({ total }) => Number(total)).reduce((sum, i) => sum + i, 0);
}

function NewInvoice({ postConfig }: IProps) {
  const navigate = useNavigate();

  const [rows, setRows] = useState<ILineItems[]>([]);

  const [open, setOpen] = useState(false);

  

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [notificationPreference, setNotificationPreference] = useState('email');
  const [errorMessage, setErrorMessage] = useState('');

  const [loading, setLoading] = useState(false);

  const handleBack = () => {
    navigate(ROUTER_URLS.invoices.index);
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSend = () => {
    setLoading(true);
    const lineItems = rows.map((row) => {
      return ({
        name: row.name,
        description: row.description,
        quantity: Number(row.quantity),
        unit_cost: Number(row.unitCost),
      })
    })

    const saveParams = {
      invoice: {
        recipient_attributes: {
          first_name: firstName,
          last_name: lastName,
          email_address: emailAddress,
          phone_number: phoneNumber,
          notification_method: notificationPreference,
        },
        invoice_line_items_attributes: lineItems,
      }
    };

    axios.post(API_V3_ALLIGATOR_URLS.invoice.create, saveParams, postConfig).then(() => {
      handleClose();
      navigate(ROUTER_URLS.invoices.index, { state: {
        sendStatus: 'success', toastMessage: `Your invoice has been sent to ${firstName} ${lastName}! The status will be updated as it is paid.`
      } });
    }).catch((e) => {
      console.log(e);
      setErrorMessage('Failed to send invoice. Please check the contact information and try again.')
    }).finally(() => {
      setLoading(false);
    });
  };

  const handlePhoneNumberChange = (event?: E164Number | undefined) => {
    setPhoneNumber(event ?? "");
  }

  const handleAddLineItem = (lineItem: { name: string; description: string; quantity: string; unitCost: string }) => {
    const current = structuredClone(rows);
    current.push(lineItem);
    setRows(current);
    handleClose();
  };

  const formComplete = () => {
    if (firstName.length === 0) return false;
    if (lastName.length === 0) return false;
    if (notificationPreference === 'email' && emailAddress.length === 0) return false;
    if (notificationPreference === 'sms' && phoneNumber.length < 6) return false;
    if (rows.length === 0) return false;
    return rows.every((row) => {
             return row.name.length > 0 && Number(row.quantity) > 0 && Number(row.unitCost) > 0;
           })
  }

  const displayItem = (id: number) => {

  };

  const deleteItem = (id: number) => {
    const current: ILineItems[] = structuredClone(rows);
    const index = current.findIndex(item => item.id === id);
    if (index !== -1) {
      current.splice(index, 1);
    } else {
        console.log(`Item with id = ${id} not found.`);
    }
    setRows(current);
  }

  const editItem = (id: number) => {

  }

  return (
    <DashboardLayout loading={loading}>
      
        <Typography component="h1" variant="h5">
          Send new invoice
        </Typography>
        <Typography variant="h6" sx={{ marginTop: 2 }}>
          Add items to build your invoice
        </Typography>
        <Grid container>
          <Grid xs={12}>
            {rows && rows.length > 0 && <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell sx={{ fontWeight: 600, fontSize: 16 }}>Item</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 600, fontSize: 16 }}>Qty.</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 600, fontSize: 16 }}>Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell>
                        <LineItemActions
                          itemId={row.id}
                          displayItem={displayItem}
                          deleteItem={deleteItem}
                          editItem={editItem}
                        />
                      </TableCell>
                      <TableCell sx={{ fontSize: 16 }}>{row.name}</TableCell>
                      <TableCell align="right" sx={{ fontSize: 16 }}>{row.quantity}</TableCell>
                      <TableCell align="right" sx={{ fontSize: 16 }}>{ccyFormat(Number(row.total))}</TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell colSpan={3} align="right" sx={{ fontSize: 16 }}>Invoice Total</TableCell>
                    <TableCell align="right" sx={{ fontSize: 16 }}>{ccyFormat(invoiceTotal(rows))}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>}
          </Grid>
          
          <Grid xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Button
              variant="contained"
              onClick={() => {
                setOpen(true);
              }}
              sx={{ my: 2 }}
              disabled={loading || open}
            >
              Add line item
            </Button>
          </Grid>
        </Grid>
        

        <Typography variant="h6">
          Who should this be sent to?
        </Typography>
        <Grid container spacing={2}>
          <Grid xs={12} lg={6} marginTop={1}>
            <TextField
              label="First name"
              type="text"
              id="first-name"
              name="first-name"
              autoComplete="given-name"
              value={firstName}
              required
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFirstName(event.target.value);
              }}
              fullWidth
            />
          </Grid>
          <Grid xs={12} lg={6} sx={{ marginTop: { xs: 0, sm: 1 } }}>
            <TextField
              label="Last name"
              type="text"
              id="last-name"
              name="last-name"
              required
              autoComplete="family-name"
              value={lastName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setLastName(event.target.value);
              }}
              fullWidth
            />
          </Grid>
          <Grid xs={12} lg={6}>
            <TextField
              type="email"
              id="email-address"
              name="email-address"
              autoComplete="email"
              label="Email Address"
              value={emailAddress}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEmailAddress(event.target.value);
              }}
              fullWidth
            />
          </Grid>
          <Grid xs={12} lg={6}></Grid>
          <Grid xs={12} lg={6}>
            <PhoneNumber
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              label="Phone Number"
              helperText="Email or phone number must be entered"
            />
          </Grid>
          <Grid xs={12}>
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">Send invoice via:</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={notificationPreference}
                onChange={(e) => { setNotificationPreference(e.target.value)}}
              >
                <FormControlLabel value="email" control={<Radio />} label="Email" />
                <FormControlLabel value="sms" control={<Radio />} label="Text" />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>

        <Box display='flex' flexDirection='row' justifyContent='flex-end'>
          <Button variant="outlined" onClick={handleBack}>Cancel</Button>
          <Button
            variant="contained"
            onClick={handleSend}
            sx={{ mx: 2 }}
            disabled={loading || !formComplete()}
            endIcon={<SendIcon />}
          >
            Send
          </Button>
        </Box>
        <Box>
          {errorMessage.length > 0 && <Typography sx={{ color: theme.palette.error.main}}>{errorMessage}</Typography>}
        </Box>
        <div>
          <NewInvoiceLineItemModal
            open={open}
            onClose={handleClose}
            onAdd={handleAddLineItem}
            postConfig={postConfig}
          />
      </div>
    </DashboardLayout>  
  );
}

export default withCommonTools(NewInvoice);