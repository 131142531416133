import React, { useEffect, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Box, Checkbox, Chip, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, OutlinedInput, Select, TextField, useTheme } from '@mui/material';
import type { SelectChangeEvent, Theme } from '@mui/material';
import HorizontalStepper from '../../components/atoms/HorizontalStepper/HorizontalStepper';
import DashboardLayout from '../../components/layouts/Dashboard/Dashboard';
import { newListingSteps } from '../../constants/listings';
import { useFetchListing } from '../../hooks/fetchListing';
import type { ListingDetailType } from '../../types/listing';
import HorizontalStepperActions from '../../components/atoms/HorizontalStepperActions/HorizontalStepperActions';
import { ROUTER_URLS } from '../../constants/router-urls';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../constants/api-urls';

import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';
import { listingStepSixValid } from '../../utils/listing';
import { useFetchSigningTemplates } from '../../hooks/fetchSigningTemplate';

interface IProps extends WrappedProps {};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(id: string, selected: readonly string[], theme: Theme) {
  return {
    fontWeight: selected.includes(id)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

function EditListingAvailability({ getConfig, postConfig, getCurrentUserId }: IProps) {
  const { id: listingId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();

  const activeStep = 5;

  const [limitNumberOfGuests, setLimitNumberOfGuests] = useState(false);
  const [minimumNumberOfGuests, setMinimumNumberOfGuests] = useState("0");
  const [maximumNumberOfGuests, setMaximumNumberOfGuests] = useState("0");
  const [templateIds, setTemplateIds] = useState<string[]>([]);
  const [isSaving, setIsSaving] = useState(false);

  const { data: listingRecord, isLoading } = useFetchListing(getConfig, listingId ?? '');
  const { data: templates, isLoading: isTemplatesLoading } = useFetchSigningTemplates(postConfig);

  useEffect(() => {
    if (listingRecord) {
      setLimitNumberOfGuests(listingRecord.enforceAccomodationRange);
      setMinimumNumberOfGuests(listingRecord.accommodateMin?.toString() ?? '');
      setMaximumNumberOfGuests(listingRecord.accommodateMax?.toString() ?? '');
      setTemplateIds(listingRecord.signingTemplates.map((record) => (record.id.toString())));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [listingRecord])

  const handleNext = () => {
    const nextUrl = generatePath(ROUTER_URLS.listings.show, { id: listingId });
    navigate({
      pathname: nextUrl,
    });
  };

  const handleSave = (moveToNextStep: boolean) => {
    setIsSaving(true);

    const params = {
      listing: {
        enforce_accomodation_range: limitNumberOfGuests,
        accommodate_min: minimumNumberOfGuests,
        accommodate_max: maximumNumberOfGuests,
        published: true,
        signing_template_ids: templateIds,
      }
    };

    axios.patch(`${API_V3_ALLIGATOR_URLS.listing.update}${listingId ?? ''}`, params, postConfig).then((response) => {
      const newListingData: ListingDetailType = response.data;
      if (newListingData.id) {
        if (moveToNextStep) handleNext();
        setIsSaving(false);
      } else {
        console.log('error failed to save');
      }
    }).catch((error) => {
      console.log(error);
    });
  };

  const handleBack = () => {
    const backUrl = generatePath(ROUTER_URLS.listings.editFileUploads, { id: listingId });
    navigate({
      pathname: backUrl,
    });
  };

  const handleLimitNumberOfGuestsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLimitNumberOfGuests(event.target.checked);
  };

  const handleTemplateChange = (event: SelectChangeEvent<typeof templateIds>) => {
    const {
      target: { value },
    } = event;
    setTemplateIds(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };


  // @todo: make this look nice with a link back to the listings page.
  // this should be returned if the listing id is null empty or undefined as well
  if (!listingRecord) {
    return <DashboardLayout loading />;
  }

  return (
    <DashboardLayout loading={isLoading || isSaving}>
      <Grid container direction='column'>
        <Grid item marginTop={5} marginBottom={10}>
          <HorizontalStepper
            activeStep={activeStep}
            steps={newListingSteps}
            listingId={listingId}
          />
          <Grid container direction='row' padding={2} spacing={2}>
            <Grid item xs={12} lg={6} marginTop={6}>
              <div>
                <Grid container direction='row' spacing={2}>
                  <Grid item xs={12}>
                    {!isTemplatesLoading && templates && <div>
                      <FormControl fullWidth sx={{ marginY: 3 }}>
                        <InputLabel id="waivers-multiple-chip-label">Electronic Forms</InputLabel>
                        <Select
                          labelId="waivers-multiple-chip-label"
                          id="waivers-multiple-chip"
                          multiple
                          value={templateIds}
                          onChange={handleTemplateChange}
                          input={<OutlinedInput id="staff-multiple-chip" label="Electronic Forms" />}
                          renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                              {selected.map((value) => {
                                const record = templates.find((template) => template.id.toString() === value);
                                return (
                                  <Chip key={value} label={record?.title ?? ''} />
                                )
                              })}
                            </Box>
                          )}
                          MenuProps={MenuProps}
                        >
                          {templates.map((template) => (
                            <MenuItem
                              key={template.id}
                              value={template.id.toString()}
                              style={getStyles(template.id.toString(), templateIds, theme)}
                            >
                              {template.title}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>Electronic forms will be sent to hunter 14 days prior to their trip</FormHelperText>
                      </FormControl>
                      </div>}
                    </Grid>
                  </Grid>
                <Grid container direction='row' spacing={2}>
                  <Grid item xs={12} lg={5}>
                    <FormControlLabel control={<Checkbox
                      checked={limitNumberOfGuests}
                      onChange={handleLimitNumberOfGuestsChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />} label="Limit number of hunters" />
                  </Grid>
                </Grid>
                {
                  limitNumberOfGuests && (
                    <Grid container direction='row' paddingY={2} spacing={2}>
                      <Grid item xs={12} lg={5}>
                        <TextField
                          id="outlined-number"
                          value={minimumNumberOfGuests}
                          onChange={(event) => { setMinimumNumberOfGuests(event.target.value)}}
                          label="Minimum"
                          type="number"
                          fullWidth
                          helperText="Minimum number of hunters"
                        />
                      </Grid>
                      <Grid item xs={12} lg={5}>
                        <TextField
                          id="outlined-number"
                          value={maximumNumberOfGuests}
                          onChange={(event) => { setMaximumNumberOfGuests(event.target.value)}}
                          label="Maximum"
                          type="number"
                          fullWidth
                          helperText="Maximum number of hunters"
                        />
                      </Grid>
                    </Grid>
                  )
                }
              </div>
            </Grid>
          </Grid>
          <Grid container direction='row' spacing={5} justifyContent='center'>
            <Grid item xs={10} marginTop={5}>
              <HorizontalStepperActions
                firstStep={false}
                finalStep={true}
                handleNext={handleSave}
                handleBack={handleBack}
                handleSave={handleSave}
                isValid={listingStepSixValid()}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default withCommonTools(EditListingAvailability);
