import type { BookingResultType } from "../types/booking";
import { listingDisplayName } from "./listing";

export const buildShortTitle = (trip: BookingResultType): string => {
  if (trip.calendarLabel && trip.calendarLabel.length > 0) return trip.calendarLabel;
  
  const name = findName(trip);
  return `${name}${trip.numberOfGuests} Hunters`;
}

export const buildLongTitle = (trip: BookingResultType): string => {
  if (trip.calendarLabel && trip.calendarLabel.length > 0) return trip.calendarLabel;

  const name = findName(trip);
  return `${name}${trip.numberOfGuests} Hunters - ${listingDisplayName(trip.listing)}`;
}

const findName = (trip: BookingResultType): string => {
  return trip.user?.name ? `${trip.user?.name} - ` : '';
}
