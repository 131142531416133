import React, { useState } from 'react';
import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';
import DashboardLayout from '../../components/layouts/Dashboard/Dashboard';
import { Box, Button, Checkbox, Chip, FormControl, FormControlLabel, InputLabel, MenuItem, OutlinedInput, Select, Stack, TextField, Typography, useTheme } from '@mui/material';
import type { SelectChangeEvent, Theme } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../constants/api-urls';
import { ROUTER_URLS } from '../../constants/router-urls';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
// import { dateFromISOString, updateTimeToCurrent } from '../../utils/dates';
import { updateTimeToCurrent } from '../../utils/dates';

// import toProperCase from '../../utils/strings';
import { useFetchListings } from '../../hooks/fetchListing';
import { range } from 'lodash';
import { PhoneNumber } from '../../components/atoms/PhoneInput/PhoneInput';
import type { E164Number } from '../../components/atoms/PhoneInput/PhoneInput';
import { listingDisplayName } from '../../utils/listing';
import { useFetchStaffMembers } from '../../hooks/fetchStaff';
import { addDays, format, isPast, subDays } from 'date-fns';
import { BOOKING_VISIBILITY_OPTIONS } from '../../constants/bookings';

interface IProps extends WrappedProps {};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(id: string, selected: readonly string[], theme: Theme) {
  return {
    fontWeight: selected.includes(id)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

function NewBooking({ postConfig }: IProps) {
  const navigate = useNavigate();
  const theme = useTheme();

  const [selectedListingId, setSelectedListingId] = useState<string>('');

  const { data: listingRecords, isLoading } = useFetchListings(postConfig);
  const { data: staffMembers, isLoading: isStaffLoading } = useFetchStaffMembers(postConfig)

  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [numberOfGuests, setNumberOfGuests] = useState('');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const [notes, setNotes] = useState('');
  const [calendarLabel, setCalendarLabel] = useState('');
  const [staffMemberIds, setStaffMemberIds] = useState<string[]>([]);
  const [arriveHalfDayEarly, setArriveHalfDayEarly] = useState(false);
  const [departHalfDayLate, setDepartHalfDayLate] = useState(false);
  const [visibility, setVisibility] = useState(BOOKING_VISIBILITY_OPTIONS[0].id);

  const [isSaving, setIsSaving] = useState(false);

  const handleBack = () => {
    navigate(ROUTER_URLS.calendar.index);
  }

  const handleSubmit = (): void => {
    setIsSaving(true);

    const arriveDaysBefore = arriveHalfDayEarly ? 0.5 : 0.0;
    const departDaysAfter = departHalfDayLate ? 0.5 : 0.0;
    let status = 'approved';
    if (startDate && isPast(startDate)) {
      status = 'completed';
    }

    const params = {
      trip: {
        listing_id: selectedListingId,
        start_date: startDate ? startDate.toISOString() : '',
        end_date: endDate ? endDate.toISOString() : '',
        status,
        number_of_guests: Number(numberOfGuests),
        notes,
        calendar_label: calendarLabel,
        contact_attributes: {
          first_name: firstName,
          last_name: lastName,
          email_address: emailAddress,
          phone_number: phoneNumber,
        },
        staff_member_ids: staffMemberIds,
        arrive_days_before: arriveDaysBefore,
        depart_days_after: departDaysAfter,
        visibility,
      }
    }
    axios.post(`${API_V3_ALLIGATOR_URLS.booking.create}`, params, postConfig).then(() => {
      navigate(ROUTER_URLS.calendar.index);
    }).catch(() => {
      setIsSaving(false);
    });
  };

  const handlePhoneNumberChange = (event?: E164Number | undefined) => {
    setPhoneNumber(event ?? "");
  }

  const handleListingSelectChange = (event: SelectChangeEvent) => {
    setSelectedListingId(event.target.value);
  };

  const handleChangeNumberOfGuests = (event: SelectChangeEvent) => {
    const value = event.target.value;
    setNumberOfGuests(value);
  }

  const handleStaffMemberChange = (event: SelectChangeEvent<typeof staffMemberIds>) => {
    const {
      target: { value },
    } = event;
    setStaffMemberIds(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleArriveHalfDayEarlyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setArriveHalfDayEarly(event.target.checked);
  };

  const handleDepartHalfDayLateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDepartHalfDayLate(event.target.checked);
  };

  const handleVisibilityChange = (event: SelectChangeEvent) => {
    setVisibility(event.target.value);
  };

  const requiredFields = (): boolean => {
    return Boolean(selectedListingId) &&
           startDate !== undefined &&
           endDate !== undefined &&
           Boolean(numberOfGuests) &&
           Boolean(firstName) &&
           Boolean(lastName);
  }


  const shortTitle = `${firstName} ${lastName} - ${numberOfGuests} Hunters`;
  const arrivalText = startDate ? `Hunter is arriving the evening before the hunt on the ${format(subDays(startDate, 1), 'do')}` : 'Arrive half day early';
  const departureText = endDate ? `Hunter is leaving the morning after the hunt on the ${format(addDays(endDate, 1), 'do')}` : 'Depart half day late';

  return (
    <DashboardLayout loading={isSaving}>
      <Stack spacing={2} sx={{ maxWidth: '1000px' }}>
        <Typography component="h1" variant="h5">
          Add new trip
        </Typography>
        <Box>
          { !isLoading &&
            <FormControl fullWidth>
              <InputLabel id="booking-status-label">Select Package*</InputLabel>
              <Select
                labelId="listing-label"
                id="listing-select"
                value={selectedListingId}
                onChange={handleListingSelectChange}
                fullWidth
                label='Select Package*'
                placeholder='Select Package*'
              >
                {listingRecords && listingRecords.length > 0 && listingRecords.map((listing) => (
                  <MenuItem key={listing.id} value={listing.id.toString()}>{listingDisplayName(listing)}</MenuItem>
                ))}
              </Select>
            </FormControl>
          }
        </Box>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box>
            <DatePicker
              label="Start Date*"
              value={startDate}
              minDate={undefined}
              maxDate={endDate}
              onChange={(newValue) => {
                updateTimeToCurrent(newValue)
                setStartDate(newValue as (Date | undefined))
              }}
              sx={{ width: '100%' }}
            />
          </Box>
          <Box>
            <DatePicker
              label="End Date*"
              value={endDate}
              minDate={startDate}
              maxDate={undefined}
              onChange={(newValue) => {
                updateTimeToCurrent(newValue)
                setEndDate(newValue as (Date | undefined))
              }}
              sx={{ width: '100%' }}
            />
          </Box>
        </LocalizationProvider>
        <Box>
          <FormControl fullWidth sx={{ marginY: 1 }}>
            <InputLabel id="number-of-guests-label">
              Number of Hunters*
            </InputLabel>
            <Select
              labelId="number-of-guests-label"
              id="number-of-guests-select"
              value={numberOfGuests}
              label='Number of Hunters*'
              onChange={handleChangeNumberOfGuests}
            >
              {range(1, 20).map((i) =>
                <MenuItem key={i} value={i.toString()}>{i}</MenuItem>
              )}
              
            </Select>
          </FormControl>
        </Box>
        <Box>
          <Typography variant="h6">
            Trip leader details
          </Typography>
        </Box>
        <Box>
          <TextField
            label="First name"
            type="text"
            id="first-name"
            name="first-name"
            autoComplete="given-name"
            value={firstName}
            required
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setFirstName(event.target.value);
            }}
            fullWidth
          />
        </Box>
        <Box>
          <TextField
            label="Last name"
            type="text"
            id="last-name"
            name="last-name"
            required
            autoComplete="family-name"
            value={lastName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setLastName(event.target.value);
            }}
            fullWidth
          />
        </Box>
        <Box>
          <TextField
            type="email"
            id="email-address"
            name="email-address"
            autoComplete="email"
            label="Email Address"
            value={emailAddress}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setEmailAddress(event.target.value);
            }}
            fullWidth
          />
        </Box>
        <Box>
          <PhoneNumber
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            label="Phone Number"
            helperText=""
          />
        </Box>
        <Box>
          <Typography variant="h6">
            Additional details
          </Typography>
        </Box>
        <Box>
          <TextField
            label="Calendar title"
            type="text"
            id="calendar-label"
            name="calendar-label"
            value={calendarLabel}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setCalendarLabel(event.target.value);
            }}
            helperText={`Defaults to: ${shortTitle}`}
            fullWidth
          />
        </Box>
        <Box>
          <TextField
            label="Notes"
            type="text"
            id="notes"
            name="notes"
            value={notes}
            multiline
            minRows={4}
            maxRows={6}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNotes(event.target.value);
            }}
            fullWidth
          />
        </Box>
        <Box>
          <FormControl fullWidth>
            <InputLabel id="visibility-label">Visibility</InputLabel>
            <Select
              labelId="visibility-label"
              id="visibility-select"
              value={visibility}
              onChange={handleVisibilityChange}
              fullWidth
              label='Visibility*'
            >
              {BOOKING_VISIBILITY_OPTIONS.map((option) => (
                <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {!isStaffLoading && staffMembers && <div>
          <FormControl fullWidth sx={{ marginY: 1 }}>
            <InputLabel id="staff-multiple-chip-label">Staff</InputLabel>
            <Select
              labelId="staff-multiple-chip-label"
              id="staff-multiple-chip"
              multiple
              value={staffMemberIds}
              onChange={handleStaffMemberChange}
              input={<OutlinedInput id="staff-multiple-chip" label="Staff" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => {
                    const member = staffMembers.find((staff) => staff.id.toString() === value);
                    return (
                      <Chip key={value} label={member?.fullName ?? ''} />
                    )
                  })}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {staffMembers.map((member) => (
                <MenuItem
                  key={member.id}
                  value={member.id.toString()}
                  style={getStyles(member.id.toString(), staffMemberIds, theme)}
                >
                  {member.fullName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>}
        <Box>
          <FormControlLabel control={<Checkbox
            checked={arriveHalfDayEarly}
            onChange={handleArriveHalfDayEarlyChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />} label={arrivalText} />
        </Box>
        <Box>
          <FormControlLabel control={<Checkbox
            checked={departHalfDayLate}
            onChange={handleDepartHalfDayLateChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />} label={departureText} />
        </Box>
        <Box display='flex' flexDirection='row' justifyContent='flex-end'>
          <Button variant="outlined" onClick={handleBack}>Cancel</Button>
          <Button variant="contained" onClick={handleSubmit} sx={{ mx: 2 }} disabled={isSaving || !requiredFields()}>Save</Button>
        </Box>
      </Stack>
    </DashboardLayout>  
  );
}

export default withCommonTools(NewBooking);