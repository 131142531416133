'use client'

import { Button } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import { useNavigate } from "react-router-dom";

function BackButton() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1)
  }

  return (
    <Button variant="text" startIcon={<ArrowBackIosNewIcon />} onClick={handleClick}>
      Back
    </Button>
  )
}

export default BackButton;