import React, { useEffect, useRef, useState } from 'react';
import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';
import DashboardLayout from '../../components/layouts/Dashboard/Dashboard';
import { Box, Button, Container, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import type { SelectChangeEvent } from '@mui/material';

import { generatePath, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../constants/api-urls';
import { ROUTER_URLS } from '../../constants/router-urls';
import { PhoneNumber } from '../../components/atoms/PhoneInput/PhoneInput';
import type { E164Number } from '../../components/atoms/PhoneInput/PhoneInput';
import { phoneNumberForInput } from '../../utils/phoneNumber';
import type { IReadFile } from '../../types/readFile';
import { computeChecksumMd5, uploadToS3 } from '../../utils/fileUpload';
import { getCroppedImg } from '../../utils/cropImage';
import FileUploadInput from '../../components/atoms/FileUploadInput/FileUploadInput';
import RoundImageCrop from '../../components/atoms/ImageCrop/ImageCrop';
import { useFetchStaffMember } from '../../hooks/fetchStaff';

interface IProps extends WrappedProps {};

const CREATABLE_USER_TYPES = [
  { id: 'guide', label: 'Guide' },
  { id: 'hostAuxillary', label: 'Misc. Staff' },
]

function StaffEditProfile({ getConfig, postConfig }: IProps) {
  const navigate = useNavigate();

  const { id: userId } = useParams();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [shortBio, setShortBio] = useState('');
  const [fullBio, setFullBio] = useState('');
  const [email, setEmail] = useState('');

  const [phoneNumber, setPhoneNumber] = useState<E164Number>("");
  const [userType, setUserType] = useState('');
  


  const [uploadedImages, setUploadedImages] = useState<IReadFile[]>([]);
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState<any>(null);
  const imagesToUploadCount = useRef(0);

  const [isSaving, setIsSaving] = useState(false);

  const { data: userRecord, isLoading } = useFetchStaffMember(getConfig, userId ?? '');

  useEffect(() => {
    if (userRecord) {
      setFirstName(userRecord.firstName);
      setLastName(userRecord.lastName);
      setShortBio(userRecord.shortBio ?? '');
      setFullBio(userRecord.longBio ?? '');
      setEmail(userRecord.email);
      if (userRecord.phoneNumber) {
        const phone = phoneNumberForInput(userRecord.phoneNumber);
        setPhoneNumber(phone);
      }
      setUserType(userRecord.userType);

      // profile pic url
    }
  }, [userRecord]);

  if (!userRecord) {
    return <DashboardLayout loading />;
  }

  const handleBack = () => {
    const path = generatePath(ROUTER_URLS.staff.show, { id: userId });
    navigate(path);
  }

  const handleSubmit = (): void => {
    setIsSaving(true);
    const params: any = {
      user: {
        first_name: firstName.trim(),
        last_name: lastName.trim(),
        short_bio: shortBio.trim(),
        long_bio: fullBio.trim(),
        email: email.trim(),
        phone_number: phoneNumber,
        user_type: userType.toLowerCase(),
      }
    }

    saveImage().then((signedId) => {
      if (signedId && signedId.length > 0) {
        params.user.avatar = signedId
      }

      axios.put(`${API_V3_ALLIGATOR_URLS.staff_members.update}${userId ?? ''}`, params, postConfig).then(() => {
        const path = generatePath(ROUTER_URLS.staff.show, { id: userId });
        navigate(path);
      }).catch((e) => {
        console.log(e)
        setIsSaving(false);
      });
    }).catch((e) => {
      console.log(e)
      setIsSaving(false);
    });
  };

  const handleUserTypeChange = (event: SelectChangeEvent) => {
    setUserType(event.target.value);
  };

  const handlePhoneNumberChange = (event?: E164Number | undefined) => {
    setPhoneNumber(event ?? "");
  }

  const readFile = async (file: File) => {
    try {
      return await new Promise((resolve) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => { resolve(reader.result) }, false)
        reader.readAsDataURL(file)
      })
    } catch (error) {
      console.log(error);
    }
  }

  const saveAfterUpload = async (file: File) => {
    try {
      const imageDataUrl = await readFile(file).catch((e) => { console.log(e) }) as string;
      if (imageDataUrl) {
        const readImage = {
          publicUrl: imageDataUrl,
          ...file,
        }
        setUploadedImages([readImage]);
      }
    } catch(e) {
      console.log(e);
    }
  };

  const getS3Url = async (newFile: File) => {
    const checkSum = await computeChecksumMd5(newFile);
    return await axios.post(
      API_V3_ALLIGATOR_URLS.directUpload.create,
      {
        blob: {
          filename: newFile.name,
          content_type: newFile.type,
          byte_size: newFile.size,
          checksum: checkSum, /* base 64 of the MD5 hash of the file */
        }
      },
      {
        headers: { Authorization: postConfig.headers.Authorization },
      }
    )
  };

  const saveImage = async (): Promise<string | undefined> => {
    if (!uploadedImages[0]?.publicUrl) {
      return undefined;
    }
    
    try {
      const croppedImage= await getCroppedImg(
        uploadedImages[0].publicUrl ?? '',
        croppedAreaPixels,
      ) as Blob;
      const newImage = new File([croppedImage], 'profilePicture');

      const s3UrlRes = await getS3Url(newImage);

      if (s3UrlRes.data.direct_upload) {
        const directUpload = s3UrlRes.data.direct_upload;

        const uploadToS3Res = await uploadToS3(directUpload.url, directUpload.headers, newImage)
        if (uploadToS3Res.status === 200) {
          return s3UrlRes.data.signed_id;
        }             
      }

    } catch (e) {
      console.error(e)
      return undefined;
    }
  }


  return (
    <DashboardLayout loading={isLoading || isSaving}>
      <Container component={Stack} maxWidth="lg" my={6}>
        <Typography component="h1" variant="h5" my={3}>
          Edit Host Profile Details
        </Typography>
        <Box sx={{ my: 3 }}>
          <TextField
            required
            id="outlined-required"
            label="First Name"
            value={firstName}
            onChange={(e) => { setFirstName(e.target.value) }}
            fullWidth
          />
        </Box>
        <Box sx={{ my: 3 }}>
          <TextField
            required
            id="outlined-required"
            label="Last Name"
            value={lastName}
            onChange={(e) => { setLastName(e.target.value) }}
            fullWidth
          />
        </Box>
        <Box sx={{ my: 3 }}>
          <TextField
            required
            id="outlined-required"
            label="Email"
            value={email}
            onChange={(e) => { setEmail(e.target.value) }}
            fullWidth
          />
        </Box>
        <Box sx={{ my: 3 }}>
          <FormControl fullWidth>
            <InputLabel id="booking-status-label">User Type</InputLabel>
            <Select
              labelId="user-type-label"
              id="user-type-select"
              value={userType}
              label="User Type"
              onChange={handleUserTypeChange}
              fullWidth
            >
              { CREATABLE_USER_TYPES.map((option) => (
                <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        
        <Box sx={{ my: 3 }}>
          <TextField
            required
            id="outlined-required"
            label="Short Bio"
            value={shortBio}
            onChange={(e) => { setShortBio(e.target.value) }}
            fullWidth
          />
        </Box>
        <Box sx={{ my: 3 }}>
          <TextField
            required
            id="outlined-required"
            label="Full Bio"
            value={fullBio}
            onChange={(e) => { setFullBio(e.target.value) }}
            fullWidth
          />
        </Box>
        <Box sx={{ my: 3 }}>
          <PhoneNumber
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            label="Contact Phone Number"
            helperText=""
          />
        </Box>

        <Box sx={{ my: 3 }}>
          <Typography component="p" variant="body1" marginTop={3}>
            Change Profile Picture
          </Typography>
          {uploadedImages.length === 0 && 
              <FileUploadInput
              promptText='Drag and drop the new profile image here or'
              buttonText='Upload Photo'
              uploadedFiles={uploadedImages}
              setUploadedFiles={setUploadedImages}
              postConfig={postConfig}
              filesToUploadCount={imagesToUploadCount}
              saveToApi={saveAfterUpload}
            />}
          {uploadedImages.length > 0 &&
            <RoundImageCrop
              uploadedImage={uploadedImages[0]}
              imgSrc={userRecord.avatarUrl ?? ''}
              setCroppedAreaPixels={setCroppedAreaPixels}
            />
          }
        </Box>
        <Box display='flex' flexDirection='row' justifyContent='flex-end'>
          <Button variant="outlined" onClick={handleBack}>Cancel</Button>
          <Button variant="contained" onClick={handleSubmit} sx={{ mx: 2 }}>Save</Button>
        </Box>
      </Container>
    </DashboardLayout>  
  );
}

export default withCommonTools(StaffEditProfile);