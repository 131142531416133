import React, { useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import { ROUTER_URLS } from '../../constants/router-urls';
import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';
import Dashboard from '../../components/layouts/Dashboard/Dashboard';
import LoadingSpinner from '../../components/atoms/LoadingSpinner/LoadingSpinner';
import { useFetchWaiverEnabled } from '../../hooks/fetchSigningTemplate';
import SigningTemplateSignUp from '../../components/compounds/SigningTemplate/SigningTemplatesSignUp';
import SigningDocumentsTable from '../../components/compounds/SigningDocument/DocumentsTable';
import NewSigningDocument from '../../components/compounds/SigningDocument/NewDocumentModal';
// import SendIcon from '@mui/icons-material/Send';

interface IProps extends WrappedProps {};

function WaiverPageBody({ enabled, timeStamp }: { enabled: boolean, timeStamp: string }) {
  return enabled ? <SigningDocumentsTable timeStamp={timeStamp} /> : <SigningTemplateSignUp />
}

function Waivers({ getConfig, postConfig, getCurrentUserEmail }: IProps) {
  // const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [timestamp, setTimestamp] = useState(new Date().toString());

  const { data: userWaiverEnabled, isLoading } = useFetchWaiverEnabled(getConfig);

  const handleClose = () => {
    setTimestamp(new Date().toString());
    setOpen(false)
  }

  return (
    <Dashboard>
      <Grid container paddingY={2} marginTop={5} alignItems='center'>
        <Grid item xs={6}>
          <Typography variant="h5">Sent for Signature</Typography>
        </Grid>
        <Grid item xs={6} display='flex' justifyContent='end'>
          {Boolean(userWaiverEnabled?.boldsign) && <>
            <Button
              type="submit"
              variant="contained"
              onClick={() => {
                setOpen(true);
              }}
              color='primary'
              
              sx={{ marginRight: 2 }}
            >
              Send New
            </Button>
          </>}
        </Grid>
      </Grid>
      { isLoading ? <LoadingSpinner /> : <WaiverPageBody enabled={Boolean(userWaiverEnabled?.boldsign)} timeStamp={timestamp} /> }
      <div>
        <NewSigningDocument
          open={open}
          onClose={handleClose}
          postConfig={postConfig}
          getCurrentUserEmail={getCurrentUserEmail}
        />
      </div>
    </Dashboard>
  );
}

export default withCommonTools(Waivers);