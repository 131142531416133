import React, { useState } from 'react';
import { Alert, Box, Button, Card, CardActions, CardContent, CardHeader, Grid, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery } from '@mui/material';
import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';
import Dashboard from '../../components/layouts/Dashboard/Dashboard';
import LoadingSpinner from '../../components/atoms/LoadingSpinner/LoadingSpinner';
import { useFetchInvoices, useFetchInvoicesEnabled } from '../../hooks/fetchInvoice';
import formatCurrency from '../../utils/currency';
// import NewInvoiceModal from '../../components/compounds/Invoice/NewInvoiceModal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import { formatDateString } from '../../utils/dates';
import { ROUTER_URLS } from '../../constants/router-urls';
import StripeSetupRequired from '../../components/compounds/StripeSetupRequired/StripeSetupRequired';
import { generatePath, useLocation } from 'react-router-dom';
import { APP_DISPLAY_NAME } from '../../constants/app-name';
import { API_V3_ALLIGATOR_URLS } from '../../constants/api-urls';
import axios from 'axios';
import type { InvoiceType } from '../../types/invoice';
import theme from '../../theme/theme';
import MoreVertIcon from '@mui/icons-material/MoreVert';

interface IProps extends WrappedProps {};
interface IDisplayProps {
  loading: boolean;
  deleteLoading: boolean;
  invoices?: InvoiceType[],
  deleteInvoice: (slug: string) => void
}
interface IMobileCardActionsProps {
  invoiceSlug: string;
  deleteEnabled: boolean;
  deleteInvoice: (slug: string) => void
};

function MobileCardActions({ invoiceSlug, deleteEnabled, deleteInvoice }: IMobileCardActionsProps) {
  // const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // const navigateToListing = (id: string) => {
  //   const path = generatePath(ROUTER_URLS.listings.show, { id });
  //   navigate(path);
  // };

  // const navigateToEdit = (id: string) => {
  //   const path = generatePath(ROUTER_URLS.listings.edit, { id });
  //   navigate(path);
  // };

  // const duplicateListing = (id: string) => {
  //   setLoading(true);
  //   const config: any = { ...postConfig };
  //   const params = {
  //     id
  //   }
  //   axios.post(API_V3_ALLIGATOR_URLS.listing.duplicate, params, config).then((response) => {
  //     const newListing = response.data;
  //     if (newListing?.id) {
  //       navigateToListing(newListing.id);
  //     }
  //   }).catch((error) => {
  //     console.log(error);
  //   }).finally(() => {
  //     setLoading(false);
  //   });
  // };

  return (
    <div>
      <IconButton
        aria-label="options"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem disabled={!deleteEnabled} onClick={() => { deleteInvoice(invoiceSlug) }}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" color='error' />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
}

function MobileTable({ loading, deleteLoading, invoices, deleteInvoice }: IDisplayProps) {
  return (
    <>
      { loading ? <LoadingSpinner /> :
        <Stack spacing={2}>
          {invoices?.map((invoice) => {
            const invoiceUrl = generatePath(ROUTER_URLS.invoices.show, { id: invoice.slug })
            return (
              <Box key={invoice.id} sx={{ minWidth: 275 }}>
                <Card variant="outlined">
                  <CardHeader
                    avatar={ invoice.status === 'completed' ? <CheckCircleIcon color='success' fontSize='inherit' /> : <CancelIcon color='error' fontSize='large' />}
                    action={
                      <MobileCardActions
                        invoiceSlug={invoice.slug}
                        deleteEnabled={invoice.paidAmount === 0 && !deleteLoading}
                        deleteInvoice={deleteInvoice}
                      />
                    }
                    title={invoice.recipient.fullName}
                    subheader={formatDateString(invoice.createdAt, 'PP')}
                    titleTypographyProps={{
                      fontSize: 20
                    }}
                  />
                  <CardContent sx={{ paddingTop: 1 }}>
                    <Grid container rowGap={1}>
                      <Grid item xs={6}>
                        <Typography sx={{ fontSize: 18 }}>
                          Total:
                        </Typography>
                        <Typography sx={{ fontSize: 16 }}>
                          { `$${formatCurrency(invoice.total / 100)}` }
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography sx={{ fontSize: 18 }}>
                          Amount Paid:
                        </Typography>
                        <Typography sx={{ fontSize: 16 }}>
                          { `$${formatCurrency(invoice.paidAmount / 100)}` }
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography sx={{ fontSize: 18 }}>
                          Fees:
                        </Typography>
                        <Typography sx={{ fontSize: 16 }}>
                          { `$${formatCurrency(invoice.feeAmount / 100)}` }
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions>
                    <Button size="small" href={invoiceUrl}>More details</Button>
                  </CardActions>
                </Card>
              </Box>
            )
          })

          }
      
      <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left" sx={{ fontWeight: 600 }}>Status</TableCell>
                <TableCell align="left" sx={{ fontWeight: 600 }}>Sent To</TableCell>
                <TableCell align="left" sx={{ fontWeight: 600 }}>Sent On</TableCell>
                <TableCell align="left" sx={{ fontWeight: 600 }}>Total</TableCell>
                <TableCell align="left" sx={{ fontWeight: 600 }}>Amount Paid</TableCell>
                <TableCell align="left" sx={{ fontWeight: 600 }}>Fees</TableCell>
                <TableCell align="left" sx={{ fontWeight: 600 }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoices?.map((row) => {
                return (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    hover={true}
                    
                  >
                    <TableCell align="left">
                      { row.status === 'completed' ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />}
                    </TableCell>
                    <TableCell align="left">{row.recipient.fullName}</TableCell>
                    <TableCell align="left">{formatDateString(row.createdAt)}</TableCell>
                    <TableCell align="left">{`$${formatCurrency(row.total / 100)}`}</TableCell>
                    <TableCell align="left">{`$${formatCurrency(row.paidAmount / 100)}`}</TableCell>
                    <TableCell align="left">{`$${formatCurrency(row.feeAmount / 100)}`}</TableCell>
                    <TableCell align="left">
                      {row.paidAmount === 0 && <IconButton disabled={deleteLoading} onClick={() => { deleteInvoice(row.slug) }}>
                        <DeleteIcon color='error' />
                      </IconButton>}
                    </TableCell>
                  </TableRow>
                )}
              )}
            </TableBody>
          </Table>
        </TableContainer>
        </Stack>}
    </>
  )
}

function DesktopTable({ loading, deleteLoading, invoices, deleteInvoice }: IDisplayProps) {
  return (
    <>
      { loading ? <LoadingSpinner /> : <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left" sx={{ fontWeight: 600 }}>Status</TableCell>
                <TableCell align="left" sx={{ fontWeight: 600 }}>Sent To</TableCell>
                <TableCell align="left" sx={{ fontWeight: 600 }}>Sent On</TableCell>
                <TableCell align="left" sx={{ fontWeight: 600 }}>Total</TableCell>
                <TableCell align="left" sx={{ fontWeight: 600 }}>Amount Paid</TableCell>
                <TableCell align="left" sx={{ fontWeight: 600 }}>Fees</TableCell>
                <TableCell align="left" sx={{ fontWeight: 600 }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoices?.map((row) => {
                return (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    hover={true}
                    
                  >
                    <TableCell align="left">
                      { row.status === 'completed' ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />}
                    </TableCell>
                    <TableCell align="left">{row.recipient.fullName}</TableCell>
                    <TableCell align="left">{formatDateString(row.createdAt)}</TableCell>
                    <TableCell align="left">{`$${formatCurrency(row.total / 100)}`}</TableCell>
                    <TableCell align="left">{`$${formatCurrency(row.paidAmount / 100)}`}</TableCell>
                    <TableCell align="left">{`$${formatCurrency(row.feeAmount / 100)}`}</TableCell>
                    <TableCell align="left">
                      {row.paidAmount === 0 && <IconButton disabled={deleteLoading} onClick={() => { deleteInvoice(row.slug) }}>
                        <DeleteIcon color='error' />
                      </IconButton>}
                    </TableCell>
                  </TableRow>
                )}
              )}
            </TableBody>
          </Table>
        </TableContainer>}
    </>
  )
}

function Waivers({ getConfig, postConfig }: IProps) {
  const { state } = useLocation();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const { sendStatus, toastMessage } = state || {};
  const [deleteLoading, setDeleteLoading] = useState(false);

  const { data: invoices, isLoading, refetch, isRefetching } = useFetchInvoices(getConfig);
  const { data: invoicesEnabled, isLoading: isPermissionLoading } = useFetchInvoicesEnabled(getConfig);


  if (!isPermissionLoading && !invoicesEnabled?.hostConnectId) {
    return (
      <Dashboard>
        <Grid container paddingY={2} marginTop={5} alignItems='center'>
          <Grid item xs={6}>
            <Typography variant="h5">Invoices</Typography>
          </Grid>
        </Grid>
        <StripeSetupRequired
          message={`Thanks for choosing ${APP_DISPLAY_NAME} to send your invoices! Before you can begin sending invoices, you need to update some information in Stripe.`}
          updateInfoPrompt='Update Stripe information'
        />
      </Dashboard>  
    )
  }

  const deleteInvoice = (slug: string) => {
    const apiRoute = API_V3_ALLIGATOR_URLS.invoice.destroy.replace(':id', slug ?? '');
    axios.delete(apiRoute, postConfig).then(() => {
      refetch().catch((e) => { console.log(e) });
      setDeleteLoading(false);
    }).catch((e) => {
      console.log(e);
      setDeleteLoading(false);
    })
  };

  return (
    <Dashboard loading={ isLoading || isPermissionLoading || isRefetching }>
      { sendStatus && sendStatus === 'success' && <Alert icon={<CheckCircleIcon fontSize="inherit" />} severity="success">
        { toastMessage }
      </Alert>}
      <Grid container paddingY={2} marginTop={1} alignItems='center'>
        <Grid item xs={6}>
          <Typography variant="h5">Invoices</Typography>
        </Grid>
        <Grid item xs={6} display='flex' justifyContent='end'>
          
            <Button
              type="submit"
              variant="contained"
              href={ROUTER_URLS.invoices.new}
              color='primary'
              sx={{ marginRight: 2 }}
            >
              Send Invoice
            </Button>
        </Grid>
      </Grid>
      {!matches ?
        <MobileTable loading={isLoading} deleteLoading={deleteLoading} invoices={invoices} deleteInvoice={deleteInvoice} />
        : <DesktopTable loading={isLoading} deleteLoading={deleteLoading} invoices={invoices} deleteInvoice={deleteInvoice} />}
      <div>
        {
          // <NewInvoiceModal
          // open={open}
          // onClose={handleClose}
          // postConfig={postConfig}
          // />
        }
      </div>
    </Dashboard>
  );
}

export default withCommonTools(Waivers);