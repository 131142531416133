import React, { useState } from 'react';
import { Box, Button, List, ListItem, Typography, Stack } from '@mui/material';
import { withCommonTools } from '../../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../../components/compounds/CommonWrapper/withCommonTools';
import { API_V3_ALLIGATOR_URLS } from '../../../constants/api-urls';
import axios from 'axios';
import { APP_DISPLAY_NAME } from '../../../constants/app-name';
import { useNavigate } from 'react-router-dom';
import { ROUTER_URLS } from '../../../constants/router-urls';
import theme from '../../../theme/theme';


interface IProps extends WrappedProps {};

function SigningTemplateSignUp({ getConfig, postConfig }: IProps) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  
  const handleSendUserConfirmation = () => {
    setLoading(true);
    axios.post(API_V3_ALLIGATOR_URLS.signingTemplates.verify, {}, postConfig).then((res) => {
      navigate(ROUTER_URLS.waiverTemplates.index, { state: {
        sendStatus: 'success', toastMessage: `Please check your email and agree to electronic signature terms and conditions`
      }, replace: true });
    }).catch((e) => {
      console.log(e);
      setErrorMessage('Failed to send electronic signature terms and conditions. Please check your email address and try again.')
    })
  }

  return (
    <Stack spacing={1} sx={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center', justifyItems: 'center' }}>
      <Typography variant='h6' align='center' fontWeight={600} sx={{ marginY: 2 }}>Follow the steps below to begin using e-waivers</Typography>
      <List sx={{ listStyleType: 'disc' }}>
        <ListItem sx={{ display: 'list-item' }}>Click the button below to agree to {APP_DISPLAY_NAME} sending electronic waiver signature requests on your behalf.</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Check the email you logged in with for a confirmation email</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Follow the steps included in the email</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Refresh this page</ListItem>
      </List>
      <Box>
        {errorMessage.length > 0 && <Typography sx={{ color: theme.palette.error.main}}>{errorMessage}</Typography>}
      </Box>
      <Box>
        <Button variant='contained' onClick={handleSendUserConfirmation} disabled={loading}>Send email confirmation</Button>
      </Box>
    </Stack>
  );
}

export default withCommonTools(SigningTemplateSignUp);