import React, { createContext, useContext, useReducer } from "react";

interface IProps {
  children?: React.ReactNode;
}

const initialState = {
  userSettings: {},
}

export const SettingsContext: any = createContext(null);
export const SettingsDispatchContext: any = createContext(null);

export const useSettingsContext = () => useContext(SettingsContext);
export const useSettingsDispatchContext = () => useContext(SettingsDispatchContext);

const settingsReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'settingsChanged':
      return {
        ...state,
        userSettings: { ...action.userSettings },
      };
    default:
      return state;
  }
};

function SettingsContextProvider({ children }: IProps) {
  const [contextState, dispatch] = useReducer(
    settingsReducer,
    initialState
  );

  return (
    <SettingsContext.Provider value={contextState}>
      <SettingsDispatchContext.Provider value={dispatch}>
        {children}
      </SettingsDispatchContext.Provider>
    </SettingsContext.Provider>
  );
};

export default SettingsContextProvider;