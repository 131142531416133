import React, { useState } from 'react';
import DashboardLayout from '../../components/layouts/Dashboard/Dashboard';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';
import { Avatar, Box, Button, Grid, Stack, Typography } from '@mui/material';
import RecordDetailHeader from '../../components/atoms/RecordDetailHeader/RecordDetailHeader';
import { ROUTER_URLS } from '../../constants/router-urls';
import RecordDetailAttribute from '../../components/atoms/RecordDetailAttribute/RecordDetailAttribute';
import { displayPhoneNumber } from '../../utils/phoneNumber';
import toProperCase from '../../utils/strings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useFetchStaffMember } from '../../hooks/fetchStaff';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../constants/api-urls';

interface IProps extends WrappedProps {};

function Staff({ getConfig, postConfig }: IProps) {
  const { id: userId } = useParams();
  const navigate = useNavigate();
  const [deleteLoading, setDeleteLoading] = useState(false);

  const { data: userRecord, isLoading } = useFetchStaffMember(getConfig, userId ?? '');

  const createEditPath = (editPath: string) => {
    return generatePath(editPath, { id: userId });
  };

  const deleteStaffMember = () => {
    const apiRoute = API_V3_ALLIGATOR_URLS.staff_members.destroy.replace(':id', userId ?? '');
    axios.delete(apiRoute, postConfig).then(() => {
      navigate(ROUTER_URLS.staff.index);
      setDeleteLoading(false);
    }).catch((e) => {
      console.log(e);
      setDeleteLoading(false);
    })
  };

  if (!userRecord) {
    return <DashboardLayout loading />;
  }

  return (
    <DashboardLayout loading={isLoading || deleteLoading}>
      <Stack>
        <RecordDetailHeader
          title="Details"
          description="General information"
          editPath={createEditPath(ROUTER_URLS.staff.edit)}
        >
          <Button variant='contained' color='error' onClick={deleteStaffMember} disabled={deleteLoading}>
            Delete
          </Button>
        </RecordDetailHeader>
        <Box sx={{ marginX: { xs: 0, md: 5 } }}>
          <RecordDetailAttribute attribute="First Name" value={userRecord.firstName} />
          <RecordDetailAttribute attribute="Last Name" value={userRecord.lastName} />
          <RecordDetailAttribute attribute="Email" value={userRecord.email} />
          <RecordDetailAttribute attribute="Phone Number" value={displayPhoneNumber(userRecord.phoneNumber) ?? ''} />
          <RecordDetailAttribute attribute="User Type" value={userRecord.userType && toProperCase(userRecord.userType)} />

          <Grid container direction='row' marginY={2}>
            <Grid item xs={12} lg={3}>
              <Typography variant='body1' sx={{ fontWeight: 600 }}>User profile picture</Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              {
                userRecord?.avatarUrl ? 
                  <Avatar alt={userRecord.fullName} src={userRecord.avatarUrl} sx={{ width: 80, height: 80 }} /> :
                  <AccountCircleIcon />
              }
            </Grid>
          </Grid>
        </Box>

        <RecordDetailHeader
          title="Emergency Contact"
          description=""
          editPath={createEditPath(ROUTER_URLS.staff.editEmergencyContact,)}
        />
        <Box sx={{ marginX: { xs: 0, md: 5 } }}>
          <RecordDetailAttribute attribute="Contact Name" value={userRecord.emergencyContactName ?? 'N/A'} />
          <RecordDetailAttribute attribute="Contact Number" value={userRecord.emergencyContactNumber ?? 'N/A'} />
        </Box>
      </Stack>
    </DashboardLayout>
  );
}

export default withCommonTools(Staff);
