import React from "react";
import { Box, CircularProgress, Paper, Stack, Typography } from "@mui/material";
import { withCommonTools } from '../CommonWrapper/withCommonTools';
import type { WrappedProps } from '../CommonWrapper/withCommonTools';
import { useFetchQrCode } from "../../../hooks/fetchQrCode";
import { useFetchInvoicesEnabled } from "../../../hooks/fetchInvoice";
import StripeConnectButton from "../StripeConnectButton/StripeConnectButton";

interface IProps extends WrappedProps {};

function TipQrCodeCard({ getConfig, postConfig }: IProps) {
  // const canAccessBookNow = useUserCanAccessBookNow();
  const { data: invoicesEnabled, isLoading: isPermissionLoading } = useFetchInvoicesEnabled(getConfig);
  const { data: qrCode, isLoading } = useFetchQrCode(postConfig, 'tip');

  return (
    <Paper elevation={2} sx={{ borderRadius: 3, height: 1, display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', justifyItems: 'center', borderBottom: 1, borderColor: 'divider', width: '100%',  }}>
        <Typography
          variant='h6'
          component='h6'
          align='center'
          sx={{
            mt: 2,
            mb: 2,
            display: 'flex',
            justifyContent: 'center',
            justifyItems: 'center',
            width: '75%',
            alignItems: 'center',
            fontWeight: 600,
          }}
        >
          Request Tip
        </Typography>
      </Box>        
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        justifyItems: 'center',
        borderColor: 'divider',
        flexGrow: 1,
        width: '100%',
      }}>
        <Stack spacing={2} sx={{ display: 'flex', flexGrow: 1, margin: 1, justifyContent: 'center', justifyItems: 'center', alignItems: 'center', }}>
        { isLoading || isPermissionLoading ?
          <Box sx={{
            mt: 3,
            mb: 3,
            display: 'flex',
            justifyContent: 'center',
            justifyItems: 'center',
            alignItems: 'center',
            minHeight: '72px',
          }}>
            <CircularProgress />
          </Box> :
          invoicesEnabled?.hostConnectId && invoicesEnabled?.hostConnectId.length > 0 ?
            <>
              {
                qrCode && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 175 }}>
                <img
                  src={qrCode.imageUrl}
                  alt='image one'
                  style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                />
              </Box>
              }
            </> : <>
              <Typography>Add payout details to begin accepting tips</Typography>
              <StripeConnectButton buttonText="Set up payout information" />
            </>
        }
        </Stack>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          justifyItems: 'center',
          width: '100%',
        }}>
          <Typography variant="body2" sx={{ paddingTop: 1, textAlign: 'center', fontStyle: 'italic', paddingX: 2, marginBottom: 1, }}>
            *Allow customers to scan to pull up the tip page on their device
          </Typography>
        </Box>
      </Box>
    </Paper>
  )
}

export default withCommonTools(TipQrCodeCard);