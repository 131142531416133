import React, { useEffect, useState } from 'react';
import { withCommonTools } from '../../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../../components/compounds/CommonWrapper/withCommonTools';
import DashboardLayout from '../../../components/layouts/Dashboard/Dashboard';
import { Box, Button, Container, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import type { SelectChangeEvent } from '@mui/material';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../../constants/api-urls';
import { ROUTER_URLS } from '../../../constants/router-urls';
import type { UsageFeeType } from '../../../types/usage_fee';

interface IProps extends WrappedProps {};


function AdminUserEditUsageFees({ getConfig, postConfig }: IProps) {
  const navigate = useNavigate();

  const { id: userId } = useParams();

  const [marketplaceUserFee, setMarkplaceUserFee] = useState('');
  const [marketplaceHostFee, setMarkplaceHostFee] = useState('');
  const [huntPayUserFee, setHuntPayUserFee] = useState('');
  const [huntPayHostFee, setHuntPayHostFee] = useState('');
  const [invoicingUserFee, setInvoicingUserFee] = useState('');
  const [invoicingHostFee, setInvoicingHostFee] = useState('');
  
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axios.get(`${API_V3_ALLIGATOR_URLS.admin.appUsageFees.list}${userId ?? ''}`, getConfig).then((response) => {
      const marketplaceFee: UsageFeeType | undefined = response.data.find((fee: UsageFeeType) => fee.source === 'marketplace');
      if (marketplaceFee) {
        setMarkplaceUserFee(marketplaceFee.userChargePercent.toString());
        setMarkplaceHostFee(marketplaceFee.hostChargePercent.toString())
      }

      const huntPayFee: UsageFeeType | undefined  = response.data.find((fee: UsageFeeType) => fee.source === 'book_now');
      if (huntPayFee) {
        setHuntPayUserFee(huntPayFee.userChargePercent.toString())
        setHuntPayHostFee(huntPayFee.hostChargePercent.toString())
      }
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      setIsLoading(false);
    });
  }, [])

  

  const handleBack = () => {
    const path = generatePath(ROUTER_URLS.admin.users.show, { id: userId });
    navigate(path);
  }

  const handleSubmit = (): void => {
    setIsSaving(true);
    const params: any = {
      usage_fees: {
        host_id: userId,
      }
    }
    if (marketplaceUserFee.length > 0 && marketplaceHostFee.length > 0) {
      params.usage_fees.marketplace = {
        user_charge_percent: marketplaceUserFee,
        host_charge_percent: marketplaceHostFee,
      }
    }
    if (huntPayUserFee.length > 0 && huntPayHostFee.length > 0) {
      params.usage_fees.book_now = {
        user_charge_percent: huntPayUserFee,
        host_charge_percent: huntPayHostFee,
      }
    }
    if (invoicingUserFee.length > 0 && invoicingHostFee.length > 0) {
      params.usage_fees.invoicing = {
        user_charge_percent: invoicingUserFee,
        host_charge_percent: invoicingHostFee,
      }
    }

    axios.post(API_V3_ALLIGATOR_URLS.admin.appUsageFees.create, params, postConfig).then(() => {
      const path = generatePath(ROUTER_URLS.admin.users.show, { id: userId });
      navigate(path);
    }).catch((e) => {
      console.log(e);
    }).finally(() => {
      setIsSaving(false);
    });
  };


  const handleChangeMarketplaceUserFeeType = (event: SelectChangeEvent) => {
    setMarkplaceUserFee(event.target.value);
  };

  const handleChangeMarketplaceHostFeeType = (event: SelectChangeEvent) => {
    setMarkplaceHostFee(event.target.value);
  };

  const handleChangeHuntPayUserFeeType = (event: SelectChangeEvent) => {
    setHuntPayUserFee(event.target.value);
  };

  const handleChangeHuntPayHostFeeType = (event: SelectChangeEvent) => {
    setHuntPayHostFee(event.target.value);
  };

  const handleChangeInvoicingUserFeeType = (event: SelectChangeEvent) => {
    setInvoicingUserFee(event.target.value);
  };

  const handleChangeInvoicingHostFeeType = (event: SelectChangeEvent) => {
    setInvoicingHostFee(event.target.value);
  };


  const rows: number[] = [];
  for (let i = 15; i >= -5; i--) {
    rows.push(i);
  }

  return (
    <DashboardLayout loading={isLoading || isSaving}>
      <Container component={Stack} maxWidth="lg" my={6}>
        <Typography component="h1" variant="h5" my={3}>
          Edit Host Usage Fees
        </Typography>
        <Typography component="p" variant="body1" my={3}>
          You must set both fees for a single app (Marketplace or HuntPay) or chaos will ensue. Really though,
          it will badly break things if you only set one of the two fees on an app. You CAN choose to only set fees on
          one app or another (Marketplace or HuntPay) and things will work fine.
        </Typography>
        <Typography component="h2" variant="h6" my={3}>
          Marketplace App
        </Typography>
        <Box sx={{ my: 3 }}>
          <FormControl fullWidth>
            <InputLabel id="marketplace-user-fee-label">User Fee %</InputLabel>
            <Select
              labelId="marketplace-user-fee-label"
              id="marketplace-user-fee-select"
              value={marketplaceUserFee}
              label="User Fee %"
              onChange={handleChangeMarketplaceUserFeeType}
            >
              { rows.map((row) => <MenuItem key={`first-${row}`} value={row.toString()}>{row}</MenuItem>) }
            </Select>
          </FormControl>
        </Box>

        <Box sx={{ my: 3 }}>
          <FormControl fullWidth>
            <InputLabel id="marketplace-host-fee-label">Host Fee %</InputLabel>
            <Select
              labelId="marketplace-host-fee-label"
              id="marketplace-host-fee-select"
              value={marketplaceHostFee}
              label="Host Fee %"
              onChange={handleChangeMarketplaceHostFeeType}
            >
              { rows.map((row) => <MenuItem key={`second-${row}`} value={row.toString()}>{row}</MenuItem>) }
            </Select>
          </FormControl>
        </Box>

        <Typography component="h2" variant="h6" my={3}>
          HuntPay App
        </Typography>
        <Box sx={{ my: 3 }}>
          <FormControl fullWidth>
            <InputLabel id="huntpay-user-fee-label">User Fee %</InputLabel>
            <Select
              labelId="huntpay-user-fee-label"
              id="huntpay-user-fee-select"
              value={huntPayUserFee}
              label="User Fee %"
              onChange={handleChangeHuntPayUserFeeType}
            >
              { rows.map((row) => <MenuItem key={`third-${row}`} value={row.toString()}>{row}</MenuItem>) }
            </Select>
          </FormControl>
        </Box>

        <Box sx={{ my: 3 }}>
          <FormControl fullWidth>
            <InputLabel id="huntpay-user-fee-label">Host Fee %</InputLabel>
            <Select
              labelId="huntpay-user-fee-label"
              id="huntpay-user-fee-select"
              value={huntPayHostFee}
              label="User Fee %"
              onChange={handleChangeHuntPayHostFeeType}
            >
              { rows.map((row) => <MenuItem key={`fourth-${row}`} value={row.toString()}>{row}</MenuItem>) }
            </Select>
          </FormControl>
        </Box>

        <Typography component="h2" variant="h6" my={3}>
          Invoicing/Tips/Misc
        </Typography>
        <Box sx={{ my: 3 }}>
          <FormControl fullWidth>
            <InputLabel id="huntpay-user-fee-label">User Fee %</InputLabel>
            <Select
              labelId="huntpay-user-fee-label"
              id="huntpay-user-fee-select"
              value={invoicingUserFee}
              label="User Fee %"
              onChange={handleChangeInvoicingUserFeeType}
            >
              { rows.map((row) => <MenuItem key={`fifth-${row}`} value={row.toString()}>{row}</MenuItem>) }
            </Select>
          </FormControl>
        </Box>

        <Box sx={{ my: 3 }}>
          <FormControl fullWidth>
            <InputLabel id="huntpay-user-fee-label">Host Fee %</InputLabel>
            <Select
              labelId="huntpay-user-fee-label"
              id="huntpay-user-fee-select"
              value={invoicingHostFee}
              label="User Fee %"
              onChange={handleChangeInvoicingHostFeeType}
            >
              { rows.map((row) => <MenuItem key={`sixth-${row}`} value={row.toString()}>{row}</MenuItem>) }
            </Select>
          </FormControl>
        </Box>
        
        <Box display='flex' flexDirection='row' justifyContent='flex-end'>
          <Button variant="outlined" onClick={handleBack}>Cancel</Button>
          <Button variant="contained" onClick={handleSubmit} sx={{ mx: 2 }}>Save</Button>
        </Box>
      </Container>
    </DashboardLayout>  
  );
}

export default withCommonTools(AdminUserEditUsageFees);