import { Button } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { API_V3_ALLIGATOR_URLS } from "../../../constants/api-urls";
import type { WrappedProps } from "../CommonWrapper/withCommonTools";
import { withCommonTools } from "../CommonWrapper/withCommonTools";

interface IProps extends WrappedProps {
  buttonText: string;
};

function StripeConnectButton({ buttonText, getConfig }: IProps) {
  const [stripeLoading, setStripeLoading] = useState(false);

  const handleStripeConnectClick = () => {
    setStripeLoading(true);
    axios.post(API_V3_ALLIGATOR_URLS.stripeConnect.create, {}, getConfig).then((res) => {
      window.location.replace(res.data.stripe_link);
      setStripeLoading(false);
    }).catch((e) => {
      console.log(e);
      setStripeLoading(false);
    })
  }
  
  return (
      <Button
        variant='contained'
        color='secondary'
        onClick={handleStripeConnectClick}
        disabled={stripeLoading}
      >
        { buttonText }
      </Button>
  );
}

export default withCommonTools(StripeConnectButton);