import React, { useEffect, useState, useCallback } from 'react';
import { useMap, useMapsLibrary} from '@vis.gl/react-google-maps';
import { Autocomplete, Box, InputAdornment, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import parse from 'autosuggest-highlight/parse';

interface Props {
  placeholder: string;
  helperText?: string;
  onPlaceSelect: (place: google.maps.places.PlaceResult | null) => void;
  resetFields: () => void;
}

export const GooglePlacesAutocompleteV2 = ({ placeholder, helperText, onPlaceSelect, resetFields }: Props) => {
  const map = useMap();
  const places = useMapsLibrary('places');

  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompleteSessionToken
  const [sessionToken, setSessionToken] =
    useState<google.maps.places.AutocompleteSessionToken>();

  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service
  const [autocompleteService, setAutocompleteService] =
    useState<google.maps.places.AutocompleteService | null>(null);

  // https://developers.google.com/maps/documentation/javascript/reference/places-service
  const [placesService, setPlacesService] =
    useState<google.maps.places.PlacesService | null>(null);

  const [predictionResults, setPredictionResults] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);

  const [googleAddressValue, setGoogleAddressValue] = React.useState<google.maps.places.AutocompletePrediction | null>(null);

  useEffect(() => {
    if (!places || !map) return;

    setAutocompleteService(new places.AutocompleteService());
    setPlacesService(new places.PlacesService(map));
    setSessionToken(new places.AutocompleteSessionToken());

    return () => { setAutocompleteService(null) };
  }, [map, places]);

  const fetchPredictions = useCallback(
    async (inputValue: string) => {
      if (!autocompleteService || !inputValue) {
        setPredictionResults([]);
        return;
      }

      if (inputValue === '') {
        setPredictionResults([]);
        return;
      }

      const request = {input: inputValue, sessionToken};
      const response = await autocompleteService.getPlacePredictions(request);
      setPredictionResults(response.predictions);
    },
    [autocompleteService, sessionToken]
  );

  const onInputChange = useCallback(
    (event: React.SyntheticEvent) => {
      const value = (event.target as HTMLInputElement)?.value;
      
      if (!value) {
        setGoogleAddressValue(null);
        setPredictionResults([]);
        resetFields();
        return;
      }
      fetchPredictions(value).catch((e) => { console.log(e) });
    },
    [fetchPredictions]
  );

  const handleSuggestionClick = useCallback(
    (placeId: string) => {
      if (!places) return;

      const detailRequestOptions = {
        placeId,
        fields: ['geometry', 'name', 'formatted_address', 'address_components', 'adr_address'],
        sessionToken
      };

      const detailsRequestCallback = (
        placeDetails: google.maps.places.PlaceResult | null
      ) => {
        onPlaceSelect(placeDetails);
        setPredictionResults([]);
        setSessionToken(new places.AutocompleteSessionToken());
      };

      placesService?.getDetails(detailRequestOptions, detailsRequestCallback);
    },
    [onPlaceSelect, places, placesService, sessionToken]
  );

  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? '';
  if (!apiKey) {
    return <></>;
  }

  return (
    <>
      <Autocomplete
        id="google-map-demo"
        // sx={{ width: 300 }}
        fullWidth
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option.description
        }
        filterOptions={(x) => x}
        options={predictionResults}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={googleAddressValue}
        onChange={(_event, newValue: google.maps.places.AutocompletePrediction | null) => {
          if (newValue?.place_id) {
            setGoogleAddressValue(newValue);
            handleSuggestionClick(newValue.place_id)
          }
        }}
        onInputChange={(event) => {
          onInputChange(event)
        }}
        renderInput={(params) => (
          <TextField {...params}
            fullWidth
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start" sx={{ color: (theme: any) => theme.palette.primary.main }}>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            placeholder={placeholder}
            helperText={helperText}
          />
        )}
        renderOption={(props, option) => {
          const matches =
            option.structured_formatting.main_text_matched_substrings ?? [];

          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match) => [match.offset, match.offset + match.length]),
          );

          const { key, ...other } = props as React.HTMLAttributes<HTMLLIElement> & { key: string };

          return (
            <li key={option.place_id} {...other}>
              <Grid container alignItems="center">
                <Grid sx={{ display: 'flex', width: 44 }}>
                  <LocationOnIcon sx={{ color: 'text.secondary' }} />
                </Grid>
                <Grid sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                  {parts.map((part, index) => (
                    <Box
                      key={index}
                      component="span"
                      sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                    >
                      {part.text}
                    </Box>
                  ))}
                  <Typography variant="body2" color="text.secondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    </>
  );
};