import React, { useEffect, useState } from 'react';
import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';
import DashboardLayout from '../../components/layouts/Dashboard/Dashboard';
import { Box, Button, Checkbox, Chip, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, OutlinedInput, Select, Stack, TextField, Typography, useTheme } from '@mui/material';
import type { SelectChangeEvent, Theme } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import CheckIcon from '@mui/icons-material/Check';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../constants/api-urls';
import { ROUTER_URLS } from '../../constants/router-urls';
import { isoStringFromDateString } from '../../utils/dates';
import type { BookingResultType } from '../../types/booking';
import { buildLongTitle } from '../../utils/calendar';
import { LISTING_COLOR_PALLETE } from '../../constants/listing-colors';
import { formatISO } from 'date-fns';
import { useFetchStaffMembers } from '../../hooks/fetchStaff';
import { BOOKING_VISIBILITY_OPTIONS } from '../../constants/bookings';

interface IProps extends WrappedProps {};

interface ITripSearchParams {
  trip: {
    start_date?: string;
    end_date?: string;
  },
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(id: string, selected: readonly string[], theme: Theme) {
  return {
    fontWeight: selected.includes(id)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

const totalHunters = (selectedBookingIds: number[], bookings: BookingResultType[]): number => {
  const selectedBookings = bookings.filter((booking) => selectedBookingIds.includes(booking.id));
  return selectedBookings.reduce((count, booking) => {
    return count + booking.numberOfGuests;
  }, 0);
}

function NewGroupBooking({ postConfig }: IProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const { startDate } = useParams();
  // const startDate = searchParams.get('start')
  // const [selectedListingId, setSelectedListingId] = useState<string>('');
  const { data: staffMembers, isLoading: isStaffLoading } = useFetchStaffMembers(postConfig)
  
  const [bookings, setBookings] = useState<BookingResultType[]>([]);
  const [selectedBookingIds, setSelectedBookingIds] = useState<number[]>([]);
  const [notes, setNotes] = useState('');
  const [calendarLabel, setCalendarLabel] = useState('');
  const [selectedColor, setSelectedColor] = useState('');
  const [staffMemberIds, setStaffMemberIds] = useState<string[]>([]);
  const [visibility, setVisibility] = useState(BOOKING_VISIBILITY_OPTIONS[0].id);
  
  const [loading, setLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setLoading(true);

    const searchParams: ITripSearchParams = {
      trip: {
        start_date: formatISO(new Date()),
        end_date: formatISO(new Date()),
      },
    };
    
    if (startDate) {
      searchParams.trip.start_date = isoStringFromDateString(startDate);
      searchParams.trip.end_date =isoStringFromDateString(startDate);
    }
  
    axios.post(API_V3_ALLIGATOR_URLS.booking.list, searchParams, postConfig).then((response) => {
      const bookingData: BookingResultType[] = response.data;
      setBookings(bookingData);
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      setLoading(false)
    });
    
  }, [startDate]);


  const handleBack = () => {
    navigate(ROUTER_URLS.calendar.index);
  }

  const handleSubmit = (): void => {
    setIsSaving(true);

    const params = {
      trip_group: {
        trip_ids: selectedBookingIds,
        notes,
        calendar_label: calendarLabel,
        hex_color: selectedColor,
        staff_member_ids: staffMemberIds,
        visibility,
      }
    }
    axios.post(`${API_V3_ALLIGATOR_URLS.groupBooking.create}`, params, postConfig).then(() => {
      navigate(ROUTER_URLS.calendar.index);
    }).catch(() => {
      setIsSaving(false);
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    // setChecked(event.target.checked);
    const checked = event.target.checked;
    const copied = [...selectedBookingIds]
    if (checked) {
      copied.push(id)
    } else {
      const index = selectedBookingIds.indexOf(id);
      copied.splice(index, 1);
    }
    setSelectedBookingIds(copied);
  };

  const handleStaffMemberChange = (event: SelectChangeEvent<typeof staffMemberIds>) => {
    const {
      target: { value },
    } = event;
    setStaffMemberIds(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  
  const requiredFields = (): boolean => {
    return selectedBookingIds.length > 0 && calendarLabel.length > 0 && selectedColor.length > 0;
  }

  const handleVisibilityChange = (event: SelectChangeEvent) => {
    setVisibility(event.target.value);
  };

  const selectedCount = totalHunters(selectedBookingIds, bookings);

  return (
    <DashboardLayout loading={loading || isSaving}>
      <Stack spacing={2} sx={{ maxWidth: '1000px' }}>
        <Box>
          <Typography component="h1" variant="h5">
            Group trips
          </Typography>
        </Box>
        <Box>
          <FormGroup>
            {bookings.map((booking) => {
              const label = buildLongTitle(booking);
              const checked = selectedBookingIds.includes(booking.id);
              return (
                <FormControlLabel
                  key={booking.id}
                  control={<Checkbox checked={checked} onChange={(e) => { handleChange(e, booking.id) }} />}
                  label={label}
                  sx={{ border: 1, borderRadius: 1, marginY: 1, marginX: 0 }}
                />
              )
            })}
          </FormGroup>
          <Typography sx={{ marginY: 1 }}>{selectedCount} total hunters in Group</Typography>
        </Box>
        <Box>
          <Typography variant="h6">
            Details
          </Typography>
        </Box>
        <Box>
          <TextField
            label="Calendar event title"
            type="text"
            id="calendar-label"
            name="calendar-label"
            value={calendarLabel}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setCalendarLabel(event.target.value);
            }}
            fullWidth
            required
          />
        </Box>
        <Box>
          <TextField
            label="Notes"
            type="text"
            id="notes"
            name="notes"
            value={notes}
            multiline
            minRows={4}
            maxRows={6}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNotes(event.target.value);
            }}
            fullWidth
          />
        </Box>
        {!isStaffLoading && staffMembers && <div>
          <FormControl fullWidth sx={{ marginY: 1 }}>
            <InputLabel id="staff-multiple-chip-label">Staff</InputLabel>
            <Select
              labelId="staff-multiple-chip-label"
              id="staff-multiple-chip"
              multiple
              value={staffMemberIds}
              onChange={handleStaffMemberChange}
              input={<OutlinedInput id="staff-multiple-chip" label="Staff" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => {
                    const member = staffMembers.find((staff) => staff.id.toString() === value);
                    return (
                      <Chip key={value} label={member?.fullName ?? ''} />
                    )
                  })}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {staffMembers.map((member) => (
                <MenuItem
                  key={member.id}
                  value={member.id.toString()}
                  style={getStyles(member.id.toString(), staffMemberIds, theme)}
                >
                  {member.fullName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>}
        <Box>
          <FormControl fullWidth>
            <InputLabel id="visibility-label">Visibility</InputLabel>
            <Select
              labelId="visibility-label"
              id="visibility-select"
              value={visibility}
              onChange={handleVisibilityChange}
              fullWidth
              label='Visibility*'
            >
              {BOOKING_VISIBILITY_OPTIONS.map((option) => (
                <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box>
          <Grid2 container sx={{ margin: { xs: 0, md: 3 }, marginBottom: 3 }}>
            <Grid2 xs={12}>
              <Typography variant='body1'>Display color *</Typography>
            </Grid2>
            {LISTING_COLOR_PALLETE.map((color) => {
              let border = {};
              if (selectedColor === color) border = { border: 3, borderColor: '#000000', };
              return (
                <Grid2 key={color} xs={3} sx={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}>
                  <Button onClick={() => { setSelectedColor(color); }}>
                    <Box sx={{
                        backgroundColor: color,
                        width: '30px',
                        height: '30px',
                        borderRadius: 2,
                        padding: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        ...border,
                      }}
                    >
                      {selectedColor === color && <CheckIcon sx={{ color: '#000' }} />}
                    </Box>
                  </Button>
                </Grid2>
              )
            })}
          </Grid2>
        </Box>
        <Box display='flex' flexDirection='row' justifyContent='flex-end'>
          <Button variant="outlined" onClick={handleBack}>Cancel</Button>
          <Button variant="contained" onClick={handleSubmit} sx={{ mx: 2 }} disabled={isSaving || !requiredFields()}>Save</Button>
        </Box>
      </Stack>
    </DashboardLayout>  
  );
}

export default withCommonTools(NewGroupBooking);