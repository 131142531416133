import { useQuery } from "react-query";
import { fetchById } from "../api/requests";
import { API_V3_ALLIGATOR_URLS } from "../constants/api-urls";
import type { QrCodeType } from "../types/qrCode";



export const useFetchQrCode = (configHeaders: { headers: { Authorization: string } }, qrCodeKey: string) => {
  const config = {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    enabled: true,
  };
  return useQuery<QrCodeType>(
    ['LandingInfo', qrCodeKey],
    async () => await fetchById(API_V3_ALLIGATOR_URLS.qrCodes.show, qrCodeKey, configHeaders),
    config,
  );
};

