import React, { useState } from 'react';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../../constants/api-urls';
import { withCommonTools } from '../../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../../components/compounds/CommonWrapper/withCommonTools';
import LoadingSpinner from '../../../components/atoms/LoadingSpinner/LoadingSpinner';
import { useFetchSigningTemplates } from '../../../hooks/fetchSigningTemplate';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTER_URLS } from '../../../constants/router-urls';

interface IProps extends WrappedProps {};

function SigningTemplateTable({ getConfig, postConfig }: IProps) {
  const navigate = useNavigate()
  const { data: waivers, isLoading: loading, refetch: reload } = useFetchSigningTemplates(getConfig);

  const [deleteLoading, setDeleteLoading] = useState(false);

  const handleDeleteWaiver = (id: number) => {
    axios.delete(`${API_V3_ALLIGATOR_URLS.signingTemplates.destroy}${id}`, postConfig).then(() => {
      reload().then(() => {}).catch((e) => {console.log(e)});
      setDeleteLoading(false);
    }).catch((e) => {
      console.log(e)
      setDeleteLoading(false);
    })
  }

  const handleEditWaiver = (id: number) => {
    const url = generatePath(ROUTER_URLS.waiverTemplates.edit, { id });
    navigate(url);
  };

  const handleViewWaiver = (id: number) => {
    const url = generatePath(ROUTER_URLS.waiverTemplates.show, { id });
    navigate(url);
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Title</TableCell>
              <TableCell align="left">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {waivers?.map((row) => {
              return (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  hover={true}
                  
                >
                  <TableCell align="left">{row.title}</TableCell>
                  <TableCell align="left">
                    <IconButton disabled={deleteLoading} onClick={() => { handleViewWaiver(row.id) }} sx={{ marginX: 1 }}>
                      <InfoIcon color="primary" />
                    </IconButton>
                    <IconButton disabled={deleteLoading} onClick={() => { handleEditWaiver(row.id) }} sx={{ marginX: 1 }}>
                      <EditIcon color="primary" />
                    </IconButton>
                    <IconButton disabled={deleteLoading} onClick={() => { handleDeleteWaiver(row.id) }} sx={{ marginX: 1 }}>
                      <DeleteIcon color='error' />
                    </IconButton>
                    
                  </TableCell>
                </TableRow>
              )}
            )}
          </TableBody>
        </Table>
      </TableContainer>
  );
}

export default withCommonTools(SigningTemplateTable);