import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import DashboardLayout from '../../components/layouts/Dashboard/Dashboard';
import RecordDetailHeader from '../../components/atoms/RecordDetailHeader/RecordDetailHeader';
import RecordDetailAttribute from '../../components/atoms/RecordDetailAttribute/RecordDetailAttribute';
import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';
import { useFetchInvoice } from '../../hooks/fetchInvoice';
import { formatDateString } from '../../utils/dates';
import formatCurrency from '../../utils/currency';

interface IProps extends WrappedProps {};

const ccyFormat = (num: number) => {
  return `$${num.toFixed(2)}`;
};

function ShowInvoice({ getConfig }: IProps) {
  const { id: invoiceId } = useParams();

  const { data: invoice } = useFetchInvoice(getConfig, invoiceId ?? '');

  if (!invoice) {
    return <DashboardLayout loading />;
  }

  return (
    <DashboardLayout>
      <Stack>
        <RecordDetailHeader
          title="Invoice Details"
          description=""
        >
          {invoice.id && invoice.paidAmount === 0 && (
            <Button variant='contained' color='error'>
              Delete
            </Button>
          )
          }
        </RecordDetailHeader>
        <Box sx={{ marginX: 1 }}>
          <RecordDetailAttribute attribute="Sent To:" value={invoice.recipient.fullName} />
          <RecordDetailAttribute attribute="Sent On:" value={formatDateString(invoice.createdAt, 'PP')} />
          <RecordDetailAttribute attribute="Total:" value={`$${formatCurrency(invoice.total / 100)}`} />
          <RecordDetailAttribute attribute="Amount Paid:" value={`$${formatCurrency(invoice.paidAmount / 100)}`} />
          <RecordDetailAttribute attribute="Fees:" value={`$${formatCurrency(invoice.feeAmount / 100)}`} />

          {invoice.lineItems.length > 0 && <>
            <Divider />
            <Stack spacing={1} sx={{ marginY: 2 }}>
              <Typography variant='h6' sx={{ fontWeight: 600 }}>
                Line Items
              </Typography>
              <Box>
                <div>
                  <Grid container>
                    <Grid xs={8}>
                      <Typography variant='body1' sx={{ fontWeight: 600, textDecoration: 'underline' }}>
                        Description
                      </Typography>
                    </Grid>
                    <Grid xs={4}>
                      <Typography variant='body1' sx={{ fontWeight: 600, textDecoration: 'underline' }}>
                        Cost
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </Box>  
              {invoice.lineItems.map((lineItem) => (
                <Box key={lineItem.id}>
                  <div>
                    <Grid container>
                      <Grid xs={8}>
                        <Typography variant='body1'>
                          {lineItem.description ?? ''}
                        </Typography>
                      </Grid>
                      <Grid xs={4}>
                        <Typography variant='body1'>
                          {ccyFormat(lineItem.total / 100)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                </Box>
              ))}
            </Stack>
          </>}
        </Box>
      </Stack>
    </DashboardLayout>
  );
}

export default withCommonTools(ShowInvoice);
