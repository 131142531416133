const BOOKING_DROPDOWN_OPTIONS = [
  'new',
  'approved',
  'cancelled',
  'denied',
  'completed',
  'transferSuccessful',
  'transferFailed',
];

export const BOOKING_STATUSES = [
  { id: 0, values: ['new', 'cancelled'], label: 'New' },
  { id: 1, values: ['approved'], label: 'Approved' },
  { id: 2, values: ['denied'], label: 'Denied' },
  { id: 3, values: ['completed', 'transferSuccessful', 'transferFailed'], label: 'Completed' },
];

export const BOOKING_VISIBILITY_OPTIONS = [
  { id: 'visibility_public', label: 'Share with staff' },
  { id: 'visibility_private', label: 'Private' },
]

export default BOOKING_DROPDOWN_OPTIONS;
