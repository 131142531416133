import { useQuery } from "react-query";
import { fetchById, fetchList } from "../api/requests";
import { API_V3_ALLIGATOR_URLS } from "../constants/api-urls";
import type { StaffMemberDetailType, StaffMemberResultType } from "../types/staffMember";


export const useFetchStaffMember = (config: { headers: { Authorization: string } }, userId?: string, ) => {
  return useQuery<StaffMemberDetailType>(
    ['StaffInfo', userId],
    async () => await fetchById(API_V3_ALLIGATOR_URLS.staff_members.show, userId, config),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

export const useFetchStaffMembers = (config: { headers: { Authorization: string } }) => {
  return useQuery<StaffMemberResultType[]>(
    ['StaffInfo'],
    async () => await fetchList(API_V3_ALLIGATOR_URLS.staff_members.list, config),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};