import { Box, Button, Chip, Dialog, DialogContent, DialogTitle, Divider, IconButton, Stack, Typography} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { useFetchCalendarBooking } from '../../../hooks/fetchBooking';
import LoadingSpinner from '../../atoms/LoadingSpinner/LoadingSpinner';
// import BookingPayoutCard from '../BookingCard/BookingPayoutCard';
// import BookingUserCard from '../BookingCard/BookingUserCard';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../../constants/api-urls';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTER_URLS } from '../../../constants/router-urls';
import RecordDetailAttribute from '../../atoms/RecordDetailAttribute/RecordDetailAttribute';
import { listingDisplayName } from '../../../utils/listing';
import { arrivalDate, departureDate, formatDateRange, formatDateString } from '../../../utils/dates';
import { camelToProper } from '../../../utils/strings';
import { displayPhoneNumber } from '../../../utils/phoneNumber';
import { useFetchPurchase } from '../../../hooks/fetchPurchase';
import { differenceInCalendarDays, format, parseISO } from 'date-fns';
import type { PurchaseType } from '../../../types/purchase';
import type { BookingCalendarStaffMemberType } from '../../../types/booking';
import MergeIcon from '@mui/icons-material/Merge';
import { useFetchTripGroup } from '../../../hooks/fetchTripGroup';
import { useEffect, useState } from 'react';
import { BOOKING_VISIBILITY_OPTIONS } from '../../../constants/bookings';

interface ConfigType {
  headers: {
      Authorization: string;
  };
}

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  getConfig: ConfigType;
  eventId?: string;
  eventType?: string;
}

const dateFormat = 'MMM d, yyyy';

const ccyFormat = (num: number) => {
  return `$${num.toFixed(2)}`;
};

function LoadingModalContent() {
  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Hunt Details
      </DialogTitle>
      <DialogContent dividers sx={{ paddingBottom: 20 }}>
        <LoadingSpinner />
      </DialogContent>
    </>
  );
}

interface FeeRow {
  id: number;
  desc: string;
  qty: number;
  unitCost: number;
  total: number;
}

const mapFeeDetails = (purchase: PurchaseType, baseDescription: string): FeeRow[] => {
  const additionalItems = purchase.lineItems.map((lineItem) => {
    // if (fee.subTotal === 0) return undefined;

    const unit = lineItem.subTotal > 0 ? lineItem.subTotal / 100 : 0;
    const price = lineItem.total > 0 ? (lineItem.total / 100) : 0;
    return {
      id: lineItem.id,
      desc: lineItem.description,
      qty: lineItem.quantity,
      unitCost: unit,
      total: price,
    }
  }).filter((fee: any) => Boolean(fee)) ?? [] as FeeRow[];

  if (additionalItems?.[0]) {
    additionalItems[0].desc = baseDescription;
  }

  return additionalItems;
};

const baseLineItemDescription = (bookingStartDate: string, bookingEndDate: string, numberOfGuests: number): string => {
  const startDate = parseISO(bookingStartDate);
  const endDate = parseISO(bookingEndDate);
  const numberOfDays = differenceInCalendarDays(endDate, startDate) + 1;
  const baseDescription = `Hunting for ${numberOfDays} days and ${numberOfGuests} hunters`;
  return baseDescription;
}

function PurchaseDetailsContent({ purchaseId, getConfig, baseDescription }: { getConfig: ConfigType, purchaseId: number, baseDescription: string }) {
  const { data: purchase } = useFetchPurchase(getConfig, purchaseId.toString());
  
  if (!purchase) {
    return (
      <></>
    )
  }
  
  const lineItems = mapFeeDetails(purchase, baseDescription);

  return (
    <Box sx={{ marginY: 4 }}>
      <Divider />
      <Stack spacing={1} sx={{ marginY: 2 }}>
        <Typography variant='h6' sx={{ fontWeight: 600 }}>
          Purchase Details
        </Typography>
        <Box>
          <div>
            <Grid container>
              <Grid xs={8}>
                <Typography variant='body1' sx={{ fontWeight: 600, textDecoration: 'underline' }}>
                  Description
                </Typography>
              </Grid>
              <Grid xs={4}>
                <Typography variant='body1' sx={{ fontWeight: 600, textDecoration: 'underline' }}>
                  Cost
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Box>
        {lineItems.map((lineItem, index) => (
          <Box key={lineItem.id}>
            <div>
              <Grid container>
                <Grid xs={8}>
                  <Typography variant='body1'>
                    {index !== 0 && `${lineItem.qty} x `}{lineItem.desc}
                  </Typography>
                </Grid>
                <Grid xs={4}>
                  <Typography variant='body1'>
                    {ccyFormat(lineItem.total)}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Box>
        ))}
        
      </Stack>
    </Box>
  )
}

function GroupedPurchasesContent({ purchaseIds, getConfig, baseDescription }: { getConfig: ConfigType, purchaseIds: number[], baseDescription: string }) {

  const [groupedPurchase, setGroupPurchase] = useState<PurchaseType>();

  useEffect(() => {
    axios.post(API_V3_ALLIGATOR_URLS.purchase.grouped, { purchase: { ids: purchaseIds }}, getConfig).then((res) => {
      setGroupPurchase(res.data)
    }).catch((e) => {
      console.log(e);
    })
  }, [purchaseIds]);

  if (!groupedPurchase) {
    return (
      <></>
    )
  }
  
  const lineItems = mapFeeDetails(groupedPurchase, baseDescription);

  return (
    <Box sx={{ marginY: 4 }}>
      <Divider />
      <Stack spacing={1} sx={{ marginY: 2 }}>
        <Typography variant='h6' sx={{ fontWeight: 600 }}>
          Purchase Details
        </Typography>
        <Box>
          <div>
            <Grid container>
              <Grid xs={8}>
                <Typography variant='body1' sx={{ fontWeight: 600, textDecoration: 'underline' }}>
                  Description
                </Typography>
              </Grid>
              <Grid xs={4}>
                <Typography variant='body1' sx={{ fontWeight: 600, textDecoration: 'underline' }}>
                  Cost
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Box>
        {lineItems.map((lineItem, index) => (
          <Box key={lineItem.id}>
            <div>
              <Grid container>
                <Grid xs={8}>
                  <Typography variant='body1'>
                    {index !== 0 && `${lineItem.qty} x `}{lineItem.desc}
                  </Typography>
                </Grid>
                <Grid xs={4}>
                  <Typography variant='body1'>
                    {ccyFormat(lineItem.total)}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Box>
        ))}
        
      </Stack>
    </Box>
  )
}

function StaffChips({ staffMembers }: { staffMembers: BookingCalendarStaffMemberType[] }) {
  const guides = staffMembers.filter((staff) => { return staff.type === 'guide' });
  const addStaff = staffMembers.filter((staff) => { return staff.type !== 'guide' });
  return (
    <>
    {guides.length > 0 && <Grid container direction='row' marginY={2}>
        <Grid  xs={12} lg={3}>
          <Typography variant='body1' sx={{ fontWeight: 600 }}>Guides:</Typography>
        </Grid>
        <Grid  xs={12} lg={6}>
          {guides.map((guide) => (<Chip key={guide.id} label={guide.fullName} sx={{ marginX: 1, ":first-child": { marginLeft: 0 } }}/>))}
        </Grid>
      </Grid>
    }
    {addStaff.length > 0 && <Grid container direction='row' marginY={2}>
        <Grid  xs={12} lg={3}>
          <Typography variant='body1' sx={{ fontWeight: 600 }}>Staff:</Typography>
        </Grid>
        <Grid  xs={12} lg={6}>
          {guides.map((guide) => (<Chip key={guide.id} label={guide.fullName} sx={{ marginX: 1, ":first-child": { marginLeft: 0 } }} />))}
        </Grid>
      </Grid>
    }
      
    </>
    
  )
}

function TripDetailsModalContent({ tripId, getConfig, onClose }: { getConfig: ConfigType, tripId: string, onClose: () => void; }) {
  const navigate = useNavigate();
  // const { data: bookingRecord } = useFetchBooking(getConfig, tripId);
  const { data: calendarTrip } = useFetchCalendarBooking(getConfig, tripId);

  if (!calendarTrip) {
    return <LoadingModalContent />
  }

  // const bookingRequested = ['requested'].includes(bookingRecord.status.toLowerCase());
  // const firstPaymentCompleted = bookingRecord.userPayments && bookingRecord.userPayments.filter((payment) => payment.status === 'completed').length > 0;
  
  // const hasStripeConnected = Boolean(userRecord?.connectAccountSetup);
  const baseDescription = baseLineItemDescription(calendarTrip.startDate, calendarTrip.endDate, calendarTrip.numberOfGuests);

  const handleDelete = () => {
    const path = API_V3_ALLIGATOR_URLS.booking.destroy.replace(':id', tripId)
    axios.delete(path, getConfig).then((res) => {
      onClose();
    }).catch((e) => {
      console.log(e);
    })
  };

  const handleEdit = () => {
    const path = generatePath(ROUTER_URLS.bookings.edit, { id: tripId });
    navigate(path);
  }

  const viewMoreLink = generatePath(ROUTER_URLS.bookings.show, { id: calendarTrip.id });
  
  let arrivalText: string | undefined;
  if (calendarTrip.arriveDaysBefore > 0) {
    const arrival = arrivalDate(calendarTrip.startDate);
    arrivalText = `Hunter is arriving the evening before the hunt on the ${format(arrival, 'do')}`;
  }

  let departureText: string | undefined;
  if (calendarTrip.departDaysAfter > 0) {
    const depature = departureDate(calendarTrip.endDate);
    departureText = `Hunter is leaving the morning after the hunt on the ${format(depature, 'do')}`;
  }

  const newGroupTripPath = generatePath(ROUTER_URLS.groupBookings.new, { startDate: formatDateString(calendarTrip.startDate, 'yyyy-MM-dd') });
  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        {(calendarTrip.editable || calendarTrip.limitedEditable) && <IconButton onClick={handleEdit}>
          <EditIcon fontSize="small" color='primary' sx={{ marginRight: 1 }} />
        </IconButton>}
        Hunt Details
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Stack sx={{ margin: { xs: 0, md: 0 }, marginBottom: 3 }} spacing={2}>
          <Box>
            <RecordDetailAttribute attribute="Package Name:" value={listingDisplayName(calendarTrip.listing)} />
            <RecordDetailAttribute attribute="Days:" value={formatDateRange(calendarTrip.startDate, calendarTrip.endDate, dateFormat)} />
            <RecordDetailAttribute attribute="Number of Guests:" value={calendarTrip.numberOfGuests?.toString()} />
            {arrivalText && <RecordDetailAttribute attribute="Arrival:" value={arrivalText} />}
            {departureText && <RecordDetailAttribute attribute="Departure:" value={departureText} />}
            {calendarTrip.user && <>
              <RecordDetailAttribute attribute="Contact name:" value={calendarTrip.user?.name} />
              {calendarTrip.showPhoneNumber && <RecordDetailAttribute attribute="Contact phone:" value={displayPhoneNumber(calendarTrip.user?.phoneNumber)} />}
            </>}
            {calendarTrip.location && <RecordDetailAttribute attribute="Location:" value={calendarTrip.location} />}
            <RecordDetailAttribute attribute="Visibility:" value={BOOKING_VISIBILITY_OPTIONS.find((option) => { return option.id === calendarTrip.visibility })?.label ?? ''} />
            {calendarTrip.staffMembers && calendarTrip.staffMembers.length > 0 && <StaffChips staffMembers={calendarTrip.staffMembers} />}
            <RecordDetailAttribute attribute="Status:" value={camelToProper(calendarTrip.status)} />
            <RecordDetailAttribute attribute="Notes:" value={calendarTrip.notes} />
            {calendarTrip.source !== 'host_added' && <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: 2 }}>  
              <Button color='primary' variant='contained' sx={{ width: 200 }} href={viewMoreLink}>
                View more details
              </Button>
            </Box>}
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>  
              <Button startIcon={<MergeIcon />} color='primary' variant='outlined' sx={{ width: 200 }} href={newGroupTripPath}>Group Trips</Button>
            </Box>
            {calendarTrip.purchaseId && <PurchaseDetailsContent purchaseId={calendarTrip.purchaseId} getConfig={getConfig} baseDescription={baseDescription} />}
          </Box>
          {calendarTrip.deletable && <>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>  
              <Button startIcon={<DeleteForeverIcon />} color='error' variant='contained' sx={{ width: 200 }} onClick={handleDelete}>Delete</Button>
            </Box>
          </>}
        </Stack>
      </DialogContent>
    </>
  )
}

function GroupTripDetailsModalContent({ groupTripId, getConfig, onClose }: { getConfig: ConfigType, groupTripId: string, onClose: () => void; }) {
  const navigate = useNavigate();
  
  const { data: groupTrip } = useFetchTripGroup(getConfig, groupTripId);

  if (!groupTrip) {
    return <LoadingModalContent />
  }
  
  const baseDescription = baseLineItemDescription(groupTrip.groupAttributes.startDate, groupTrip.groupAttributes.endDate, groupTrip.groupAttributes.numberOfGuests);

  const handleDelete = () => {
    const path = API_V3_ALLIGATOR_URLS.groupBooking.destroy.replace(':id', groupTripId)
    axios.delete(path, getConfig).then((res) => {
      onClose();
    }).catch((e) => {
      console.log(e);
    })
    
  };

  const handleEdit = () => {
    const path = generatePath(ROUTER_URLS.groupBookings.edit, { id: groupTripId, startDate: formatDateString(groupTrip.groupAttributes.startDate, 'yyyy-MM-dd') });
    navigate(path);
  }

  let arrivalText: string | undefined;
  if (groupTrip.groupAttributes.arriveDaysBefore > 0) {
    const arrival = arrivalDate(groupTrip.groupAttributes.startDate);
    arrivalText = `Hunters are arriving the evening before the hunt on the ${format(arrival, 'do')}`;
  }

  let departureText: string | undefined;
  if (groupTrip.groupAttributes.departDaysAfter > 0) {
    const depature = departureDate(groupTrip.groupAttributes.endDate);
    departureText = `Hunters are leaving the morning after the hunt on the ${format(depature, 'do')}`;
  }
  // typescript gets angry about not understanding the filter here cast to tell it its fine and ponder using javascript.
  // maybe thatll show it
  const purchaseIds = groupTrip.trips.map((trip) => { return trip.purchaseId }).filter((id) => { return Boolean(id) }) as unknown as number[];

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        <IconButton onClick={handleEdit}>
          <EditIcon fontSize="small" color='primary' sx={{ marginRight: 1 }} />
        </IconButton>
        Group Hunt Details
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Stack sx={{ margin: { xs: 0, md: 0 }, marginBottom: 3 }} spacing={2}>
          <Box>
            <RecordDetailAttribute attribute="Package Name:" value={groupTrip.groupAttributes.packageName} />
            <RecordDetailAttribute attribute="Days:" value={formatDateRange(groupTrip.groupAttributes.startDate, groupTrip.groupAttributes.endDate, dateFormat)} />
            <RecordDetailAttribute attribute="Number of Guests:" value={groupTrip.groupAttributes.numberOfGuests?.toString()} />
            {arrivalText && <RecordDetailAttribute attribute="Arrival:" value={arrivalText} />}
            {departureText && <RecordDetailAttribute attribute="Departure:" value={departureText} />}
            <Grid container direction='row' marginY={2}>
              <Grid xs={12} lg={3}>
                <Typography variant='body1' sx={{ fontWeight: 600 }}>Group members:</Typography>
              </Grid>
              <Grid xs={12} lg={6}>
                {groupTrip.trips.map((trip) => {
                  const user = trip.userDetails;
                  if (!user) return <></>;

                  return (
                    <Box sx={{ border: 1, borderRadius: 1, marginY: 1, marginX: 0, padding: .5 }}>
                      <Typography variant="body1">{user.name}</Typography>
                      {trip.showPhoneNumber && <Typography variant="body1">{displayPhoneNumber(user.phoneNumber)}</Typography>}
                    </Box>
                    
                  )
                })}
              </Grid>
            </Grid>
            {groupTrip.groupAttributes.location && <RecordDetailAttribute attribute="Location:" value={groupTrip.groupAttributes.location} />}
            <RecordDetailAttribute attribute="Visibility:" value={BOOKING_VISIBILITY_OPTIONS.find((option) => { return option.id === groupTrip.visibility })?.label ?? ''} />
            {groupTrip.staffMembers && groupTrip.staffMembers.length > 0 && <StaffChips staffMembers={groupTrip.staffMembers} />}
            
            <RecordDetailAttribute attribute="Notes:" value={groupTrip.notes} />

            {purchaseIds.length > 0 && <GroupedPurchasesContent purchaseIds={purchaseIds} getConfig={getConfig} baseDescription={baseDescription} />}
          </Box>
          
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>  
            <Button startIcon={<DeleteForeverIcon />} color='error' variant='contained' sx={{ width: 200 }} onClick={handleDelete}>Ungroup Trips</Button>
          </Box>
          
        </Stack>
      </DialogContent>
    </>
  )
}


export default function CalendarTripModal(props: SimpleDialogProps) {
  const { onClose, open, getConfig, eventId, eventType } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="md">
      { eventId && eventType === 'booking' && <TripDetailsModalContent getConfig={getConfig} tripId={eventId} onClose={handleClose} /> }
      { eventId && eventType === 'tripGroup' && <GroupTripDetailsModalContent getConfig={getConfig} groupTripId={eventId} onClose={handleClose} /> }
    </Dialog>
  );
}
